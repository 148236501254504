import React from 'react';
import {useAccessToken} from "../services/AccessTokenProvider";

const AccessTokenLoader = ({ children }) => {
    const { isLoading, error } = useAccessToken();

    if (isLoading) {
        return <div>ACCCESSTOKENLOADER...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>; // Global error state
    }

    return <>{children}</>;
};

export default AccessTokenLoader;