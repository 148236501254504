import "./ShopItemDetail.css";
import React, { useEffect, useState } from "react";
import { useAccessToken } from "../../../../../services/AccessTokenProvider";
import { json } from "react-router-dom";

function ShopItemDetail(props) {
	const { accessToken } = useAccessToken();
	const [isOwned, setIsOwned] = useState(props.item.is_owned);

	const buyItem = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_BASE_URL}/abilities/purchase/${props.item.id}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${accessToken}`,
					},
				},
			);

			if (!response.ok) {
				// Handle non-2xx status codes
				const errorData = await response.json();
				console.error("Purchase failed:", errorData.message);
			} else {
				// Handle successful purchase
				props.updateAbility(props.item.id, { is_owned: true });
				setIsOwned(true);
			}
		} catch (error) {
			console.error(
				"There was a problem with the buy item operation",
				error,
			);
			// Optionally, display error to the user
		}
	};

	const equipItem = async () => {
		const payload = {
			equip: true,
		};
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_BASE_URL}/abilities/toggle-equip/${props.item.id}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${accessToken}`,
					},
					body: JSON.stringify(payload),
				},
			);

			if (!response.ok) {
				// Handle non-2xx status codes
				const errorData = await response.json();
				console.error("Equip failed:", errorData.message);
			} else {
				// Handle successful equip
				props.updateAbility(props.item.id, { is_equipped: true });
			}
		} catch (error) {
			console.error(
				"There was a problem with the equip item operation",
				error,
			);
			// Optionally, display error to the user
		}
	};

	return (
		<div className="ShopItemDetail">
			<button onClick={() => props.onBack()}>Return</button>
			{isOwned ? (
				<button onClick={() => equipItem()}>Equip</button> // Render this if the item is already owned
			) : (
				<button onClick={() => buyItem()}>Buy</button> // Render this if the item is not owned
			)}
		</div>
	);
}

export default ShopItemDetail;
