import React from "react";
import "./DialogPickerItem.css";
function DialogPickerItem({ children, id, clickEvent }) {
	const handleOnClick = () => {
		clickEvent(id);
	};
	return (
		<div className="DialogPickerItem" onClick={handleOnClick}>
			{children}
		</div>
	);
}

export default DialogPickerItem;
