const themeConfig = {
	palace: {
		default: {
			backgroundColor: "white",
		},
		dark: {
			backgroundColor: "#092635",
		},
		transparent: {
			backgroundColor: "#092635",
		},
	},
	room: {
		default: {
			gridColor: "rgb(0, 0, 0, 0.3)",
		},
		dark: {
			gridColor: "rgb(255, 255, 255, 0.3)",
		},
		transparent: {
			gridColor: "rgb(255, 255, 255, 0.3)",
		},
	},
	dataTable: {
		default: {
			container: {
				backgroundColor: "white",
				trackColor: "white",
				scrollbarColor: "rgb(96, 96, 96, 0.3)",
				scrollbarHoverColor: "rgb(96, 96, 96, 0.6)",
			},
			table: {
				header: { backgroundColor: "#595959", color: "white" },
				row: {
					backgroundColor: "transparent",
					isSelectedColor: "rgb(255, 165, 0, 1)",
					color: "rgb(33, 33, 33)",
				},
			},
			iconButton: {
				color: "white",
			},
			editableCell: {
				displayColor: "white",
				inputColor: "rgb(255, 165, 0, 1)",
				secondaryInputColor: "rgb(255, 165, 0, 0.5)",
			},
		},
		dark: {
			container: {
				backgroundColor: "#092635",
				trackColor: "transparent",
				scrollbarColor: "white",
				scrollbarHoverColor: "grey",
			},
			table: {
				header: { backgroundColor: "white", color: "rgb(33, 33, 33)" },
				row: {
					backgroundColor: "transparent",
					isSelectedColor: "#3a3a3a",
					color: "white",
				},
			},
			iconButton: {
				color: "rgb(33, 33, 33)",
			},
			editableCell: {
				displayColor: "white",
				inputColor: "orange",
				secondaryInputColor: "dark-blue",
			},
		},
		transparent: {
			container: {
				backgroundColor: "#092635",
				trackColor: "transparent",
				scrollbarColor: "white",
				scrollbarHoverColor: "grey",
			},
			table: {
				header: { backgroundColor: "white", color: "rgb(33, 33, 33)" },
				row: {
					backgroundColor: "transparent",
					isSelectedColor: "#3a3a3a",
					color: "white",
				},
			},
			iconButton: {
				color: "rgb(33, 33, 33)",
			},
			editableCell: {
				displayColor: "white",
				inputColor: "orange",
				secondaryInputColor: "dark-blue",
			},
		},
	},
};

export default themeConfig;
