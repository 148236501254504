import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import icons from "../../../../../utils/customReusableComponents/IconPicker/icons";
import "./RoomConfig.css";
import IconPicker from "../../../../../utils/customReusableComponents/IconPicker/IconPicker";

function RoomConfig({ registerApplyConfig, setSubPage }) {
	const [roomName, setRoomName] = useState("");
	const [selectedIcon, setSelectedIcon] = useState(icons[0]); // Default icon

	const validateFields = () => {
		return roomName !== "";
	};

	registerApplyConfig(() => {
		if (validateFields()) {
			let config = {
				roomName: roomName,
				icon: selectedIcon.name,
				elements: [],
			};
			return config;
		}
	});

	const handleIconSelect = (iconObj) => {
		setSubPage(null);
		setSelectedIcon(iconObj);
	};

	const handleIconClick = () => {
		setSubPage(
			<IconPicker onIconSelect={handleIconSelect} icons={icons} />,
		);
	};

	return (
		<div className="RoomConfig">
			<h2>Room Settings</h2>
			<div className="icon-preview">
				<IconButton onClick={handleIconClick}>
					<selectedIcon.component />
				</IconButton>
			</div>
			<TextField
				id="outlined-basic"
				label="Room Name"
				variant="outlined"
				value={roomName}
				onChange={(e) => setRoomName(e.target.value)}
				sx={{ width: "300px" }}
			/>
		</div>
	);
}

export default RoomConfig;
