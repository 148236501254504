import "./AbilityShop.css";
import ShopItem from "./ShopItem/ShopItem";
import { useEffect, useState } from "react";
import { useAccessToken } from "../../../../services/AccessTokenProvider";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import ShopItemDetail from "./ShopItemDetail/ShopItemDetail";
import ability from "../Ability/Ability";

function AbilityShop(props) {
	const { abilities, onClose } = props;
	const { accessToken } = useAccessToken();
	const [view, setView] = useState("shop");
	const [selectedShopItem, setSelectedShopItem] = useState(null);

	const handleChangeShopItemsList = (event, newView) => {
		if (newView !== null) {
			setView(newView);
		}
	};

	const handleShopItemDetailView = (item) => {
		setSelectedShopItem(item);
	};

	const getFilteredShopItems = () => {
		switch (view) {
			case "owned":
				return abilities.filter((ability) => ability.is_owned);
			case "shop":
				return abilities.filter((ability) => !ability.is_owned);
			case "all":
			default:
				return abilities;
		}
	};

	return (
		<div className="AbilityShop">
			<button className="AbilityShop-close" onClick={onClose}>
				X
			</button>

			{selectedShopItem ? (
				<ShopItemDetail
					item={selectedShopItem}
					updateAbility={props.updateAbility}
					onBack={() => setSelectedShopItem(null)}
				/>
			) : (
				<>
					<ToggleButtonGroup
						className="AbilityShop-changeViewToggleButtonGroup"
						color="primary"
						value={view}
						exclusive
						onChange={handleChangeShopItemsList}
						sx={{
							"& .MuiToggleButtonGroup-grouped": {
								color: "white", // DataTable color for all buttons
								borderColor: "white", // Border color for all buttons
								"&.Mui-selected": {
									color: "white", // DataTable color for the selected button
									bgcolor: "rgba(241, 90, 34, 0.8)", // Background color for the selected button
									"&:hover": {
										bgcolor: "rgba(0, 90, 34, 1)", // Hover background color for selected buttons
									},
								},
								"&:hover": {
									bgcolor: "rgba(100, 150, 230, 1)", // Hover background color for buttons
								},
							},
						}}
					>
						<ToggleButton
							className="AbilityShop-changeViewToggleButton"
							value="owned"
						>
							Owned
						</ToggleButton>
						<ToggleButton
							className="AbilityShop-changeViewToggleButton"
							value="shop"
						>
							Shop
						</ToggleButton>
						<ToggleButton
							className="AbilityShop-changeViewToggleButton"
							value="all"
						>
							All
						</ToggleButton>
					</ToggleButtonGroup>
					<div className="shopItems-container">
						{getFilteredShopItems().map((item, index) => (
							<ShopItem
								key={index}
								{...item}
								onClick={() => handleShopItemDetailView(item)}
							/>
						))}
					</div>
				</>
			)}
		</div>
	);
}

export default AbilityShop;
