import React, { useEffect, useRef } from "react";
import "./Room3d.css";

const Room3d = ({
	width,
	height,
	gridFieldSize,
	children,
	animation,
	type = "room",
}) => {
	const cubeRef = useRef(null);

	useEffect(() => {
		const updateCubeSidesOnSizeChange = () => {
			if (cubeRef.current) {
				const cubeWidth = gridFieldSize * width;
				const cubeHeight = gridFieldSize * height;
				const cubeSides = cubeRef.current.children;

				for (let i = 0; i < cubeSides.length; i++) {
					const side = cubeSides[i];
					if (i === 0)
						side.style.transform = `rotateY(0deg) translateZ(${cubeWidth / 2}px)`;
					if (i === 1)
						side.style.transform = `rotateY(90deg) translateZ(${cubeWidth / 2}px)`;
					if (i === 2)
						side.style.transform = `rotateY(180deg) translateZ(${cubeWidth / 2}px)`;
					if (i === 3)
						side.style.transform = `rotateY(-90deg) translateZ(${cubeWidth / 2}px)`;
					if (i === 4) {
						side.style.transform = `rotateX(90deg) translateZ(${cubeWidth / 2}px)`;
						side.style.height = `${cubeWidth}px`;
					}
					if (i === 5) {
						side.style.transform = `rotateX(-90deg) translateZ(${-cubeWidth / 2 + cubeHeight}px)`;
						side.style.height = `${cubeWidth}px`;
					}
				}
			}
		};

		// Initial call to set translateZ
		updateCubeSidesOnSizeChange();
	}, [width, height, gridFieldSize]);

	const sideStyle =
		type === "room"
			? {
					backgroundColor: "white",
					color: "white",
				}
			: {
					backgroundColor: "transparent",
					color: "white",
					border: "1px solid white",
				};

	return (
		<div className={`cube-container ${animation}`}>
			<div className="cube" ref={cubeRef}>
				<div className="cube-side" style={sideStyle}>
					{children}
				</div>
				{[...Array(5)].map((_, i) => (
					<div key={i} className="cube-side" style={sideStyle}>
						{children}
					</div>
				))}
			</div>
		</div>
	);
};

export default Room3d;
