import "./ThoughtPalace.css";
import React, { useEffect, useState } from "react";
import Palace from "./Palace/PalaceSquare";
import ThoughtInput from "./ThoughtInput/ThoughtInput";
import AiWindow from "./AiWindow/AiWindow";

function ThoughtPalace(props) {
	const [response, setResponse] = useState("");

	return (
		<div className="ThoughtPalace">
			<Palace />
			<AiWindow response={response} />
			<ThoughtInput showResponse={setResponse} />
		</div>
	);
}

export default ThoughtPalace;
