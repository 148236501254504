import React from "react";
import DataTable from "../views/Palace/Room/DataTable/DataTable";
import Archive from "../views/Palace/Room/FileStorage/Archive";
import ActionButton from "../views/Palace/Room/Button/ActionButton";
import TextInput from "../views/Palace/Room/TextInput/TextInput";
import Calendar from "../views/Palace/Room/Calendar/Calendar";
import Note from "../views/Palace/Room/Note/Note";
import Room from "../views/Palace/Room/Room";
import Room3d from "../views/Palace/Room/Room3d/Room3d";
import Text from "../views/Palace/Room/Text/Text";
import ActionDialog from "../views/Palace/Room/ActionDialog/ActionDialog";

export const renderElement = (element, level = 0) => {
	// Define the common props
	const commonProps = {
		id: element.id,
	};

	// Choose the component based on element type
	let component;
	switch (element.type) {
		case "DataTable":
			component = <DataTable tableName={element.tableName} />;
			break;
		case "FileStorage":
			component = <Archive />;
			break;
		case "ActionButton":
			component = <ActionButton />;
			break;
		case "Text":
			component = <Text content={element.content} />;
			break;
		case "TextInput":
			component = <TextInput />;
			break;
		case "Calendar":
			component = <Calendar calendarId={element.calendarId} />;
			break;
		case "Note":
			component = <Note noteId={element.noteId} />;
			break;
		case "ActionDialog":
			component = <ActionDialog actionName={element.actionName} />;
			break;
		case "Room":
			component = (
				<Room
					isEditMode={false}
					elements={element.elements}
					level={level + 1}
					roomName={element.roomName}
					iconName={element.icon}
				/>
			);
			break;
		case "Room3d":
			component = <Room3d />;
			break;
		default:
			return null;
	}

	// Apply common props using React.cloneElement
	return React.cloneElement(component, commonProps);
};

export const getEventCoordinates = (e) => {
	const event = e.touches ? e.touches[0] : e;
	return { x: event.clientX, y: event.clientY };
};

export function debounce(func, timeout = 300) {
	let timer;
	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			func.apply(this, args);
		}, timeout);
	};
}
