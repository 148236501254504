import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Auth0Provider } from "@auth0/auth0-react";
import { AccessTokenProvider } from "./services/AccessTokenProvider";
import { UserDataProvider } from "./services/UserDataProvider";
import AccessTokenLoader from "./Authentication/AccessTokenLoader";

const root = ReactDOM.createRoot(document.getElementById("root"));

const theme = createTheme({
	components: {
		MuiTextField: {
			styleOverrides: {
				root: {
					margin: "10px",
				},
			},
		},
	},
});

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;

root.render(
	<React.StrictMode>
		<Auth0Provider
			domain={domain}
			clientId={clientId}
			authorizationParams={{
				redirect_uri: redirectUri,
				audience: `https://thought-palace`,
				scope: "openid offline_access read:current_user",
			}}
			useRefreshTokens={true} // Enable refresh tokens
			cacheLocation="localstorage" // Ensure tokens are stored in localStorage for better compatibility
		>
			<AccessTokenProvider>
				<AccessTokenLoader>
					<UserDataProvider>
						<ThemeProvider theme={theme}>
							<App />
						</ThemeProvider>
					</UserDataProvider>
				</AccessTokenLoader>
			</AccessTokenProvider>
		</Auth0Provider>
	</React.StrictMode>,
);
