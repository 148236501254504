import React, { useState, useEffect } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import * as Icons from "@mui/icons-material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useAccessToken } from "../../../../../../services/AccessTokenProvider";
import "./ActionDialogConfig.css";

function ActionDialogConfig({ onConfigurationComplete }) {
	const { accessToken } = useAccessToken();
	const [groups, setGroups] = useState([]);
	const [openItems, setOpenItems] = useState({});

	useEffect(() => {
		const fetchActionsOverview = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_BASE_URL}/thought-palace/get-actions-overview`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${accessToken}`,
						},
					},
				);

				if (response.ok) {
					const data = await response.json();
					setGroups(data);
				} else {
					console.error(
						"Failed to fetch actions overview:",
						response.statusText,
					);
				}
			} catch (error) {
				console.error("Error fetching actions overview:", error);
			}
		};

		fetchActionsOverview();
	}, [accessToken]);

	const handleActionClick = (actionName) => {
		const config = { actionName };
		if (onConfigurationComplete) {
			onConfigurationComplete(config);
		}
	};

	const renderActions = (actions, level = 0) => {
		const baseIndent = 4; // Base indent increment per level
		const paddingLeft = level === 0 ? 2 : 2 + baseIndent * level; // Level 1 has 16px padding; add 4px for each additional level

		return actions.map((action) => {
			const isExpandable = action.actions && action.actions.length > 0;
			const identifier = action.name || action.display_name;
			const isOpen = openItems[identifier] || false;
			const IconComponent = Icons[action.icon] || Icons.HelpOutline;

			return (
				<React.Fragment key={identifier}>
					<Tooltip
						title={action.description || "No description available"}
						placement="right"
					>
						<ListItemButton
							onClick={() => {
								if (isExpandable) {
									// Toggle expand/collapse
									setOpenItems((prevState) => ({
										...prevState,
										[identifier]: !isOpen,
									}));
								} else {
									// Call action click handler for non-expandable items
									handleActionClick(action.name);
								}
							}}
							sx={{
								pl: paddingLeft,
								width: "100%", // Ensure consistent width
								maxHeight: 56, // Fixed height for consistency
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							<ListItemIcon>
								<IconComponent />
							</ListItemIcon>
							<ListItemText
								primary={action.display_name || action.name}
								primaryTypographyProps={{ noWrap: true }}
							/>
							<Box
								sx={{
									width: 24, // Fixed space for expand/collapse icons
									display: "flex",
									justifyContent: "center",
								}}
							>
								{isExpandable ? (
									isOpen ? (
										<ExpandLess />
									) : (
										<ExpandMore />
									)
								) : null}
							</Box>
						</ListItemButton>
					</Tooltip>
					{isExpandable && (
						<Collapse in={isOpen} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								{renderActions(action.actions, level + 1)}
							</List>
						</Collapse>
					)}
				</React.Fragment>
			);
		});
	};

	return (
		<div className="ActionDialogConfig">
			<List
				sx={{
					width: "360px", // Fixed width to prevent shifts
					bgcolor: "background.paper",
				}}
			>
				{groups.map((group) => {
					const isExpandable =
						group.actions && group.actions.length > 0;
					const isOpen = openItems[group.group_name] || false;
					const GroupIcon =
						Icons[group.group_icon] || Icons.HelpOutline;

					return (
						<React.Fragment key={group.group_name}>
							<ListItemButton
								onClick={() => {
									// Toggle group expand/collapse
									setOpenItems((prevState) => ({
										...prevState,
										[group.group_name]: !isOpen,
									}));
								}}
								sx={{
									pl: 2, // Same padding on the left as on the right
									pr: 2, // Symmetrical padding on the right
									width: "100%", // Fixed width for first level
									maxHeight: 56, // Fixed height
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
								}}
							>
								<ListItemIcon>
									<GroupIcon />
								</ListItemIcon>
								<ListItemText
									primary={group.group_name}
									primaryTypographyProps={{ noWrap: true }}
								/>
								<Box
									sx={{
										width: 24, // Fixed width for expand/collapse icons
										display: "flex",
										justifyContent: "center",
									}}
								>
									{isExpandable ? (
										isOpen ? (
											<ExpandLess />
										) : (
											<ExpandMore />
										)
									) : null}
								</Box>
							</ListItemButton>

							{isExpandable && (
								<Collapse
									in={isOpen}
									timeout="auto"
									unmountOnExit
								>
									<List component="div" disablePadding>
										{renderActions(group.actions, 1)}
									</List>
								</Collapse>
							)}
						</React.Fragment>
					);
				})}
			</List>
		</div>
	);
}

export default ActionDialogConfig;
