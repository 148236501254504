// config.js
import StorageIcon from "@mui/icons-material/Storage";
import TableChartIcon from "@mui/icons-material/TableChart";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import RoomIcon from "@mui/icons-material/Room";
import DescriptionIcon from "@mui/icons-material/Description";
import DescriptionSharpIcon from "@mui/icons-material/DescriptionSharp";

export const coverConfig = {
	DataTable: {
		minWidth: 8,
		minHeight: 3,
		expandDefaultWidth: 12,
		expandDefaultHeight: 12,
		icon: <TableChartIcon fontSize="large" />,
		title: "Data Table",
	},
	FileStorage: {
		minWidth: 6,
		minHeight: 4,
		expandDefaultWidth: 12,
		expandDefaultHeight: 12,
		icon: <StorageIcon fontSize="large" />,
		title: "File Storage",
	},
	Text: {
		minWidth: 0,
		minHeight: 0,
		expandDefaultWidth: 12,
		expandDefaultHeight: 12,
		icon: <TextFieldsIcon fontSize="large" />,
		title: "Text",
	},
	TextInput: {
		minWidth: 0,
		minHeight: 0,
		expandDefaultWidth: 12,
		expandDefaultHeight: 12,
		icon: <DescriptionIcon fontSize="large" />,
		title: "Text Input",
	},
	Room: {
		minWidth: 0,
		minHeight: 0,
		expandDefaultWidth: 12,
		expandDefaultHeight: 12,
		icon: <RoomIcon fontSize="large" />,
		title: "Room",
	},
	Calendar: {
		minWidth: 11,
		minHeight: 7,
		expandDefaultWidth: 12,
		expandDefaultHeight: 12,
		title: "Calendar",
	},
	Note: {
		minWidth: 11,
		minHeight: 9,
		expandDefaultWidth: 12,
		expandDefaultHeight: 12,
		icon: <DescriptionSharpIcon fontSize="large" />,
		title: "Note",
	},
	ActionDialog: {
		minWidth: 4,
		minHeight: 5,
		expandDefaultWidth: 6,
		expandDefaultHeight: 6,
		icon: <DescriptionSharpIcon fontSize="large" />,
		title: "Action",
	},
};
