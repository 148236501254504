import './Payments.css'

function Payments (props) {

    return (
        <div className="Payments">
            <div className="Payments-credit"></div>

        </div>

    )
}

export default Payments;