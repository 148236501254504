import React, { useState, useEffect, useRef } from 'react';
import './AiWindow.css';

function AiWindow(props) {
    const [typedText, setTypedText] = useState('');
    const index = useRef(0);
    const containerRef = useRef(); // Ref for the outer container

    // generate text into AiWindow
    useEffect(() => {
        if (index.current < props.response.length) {
            const timeoutId = setTimeout(() => {
                setTypedText(props.response.substring(0, index.current + 1));
                index.current++;

                // fix scrollbar to bottom
                containerRef.current.scrollTop = containerRef.current.scrollHeight;
            }, 30); // Adjust typing speed
            return () => clearTimeout(timeoutId);
        } else {
            // reset index after the last letter
            index.current = 0;
        }
    }, [props.response, typedText]);

    return (
        <div className="AIWindow" ref={containerRef}>
            <div className="inner-container">
                {typedText}
            </div>
        </div>
    );
}

export default AiWindow;