import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import Paragraph from "@editorjs/paragraph";
import { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import "./Note.css";
import { debounce } from "../../../../utils/utils";
import { useAccessToken } from "../../../../../services/AccessTokenProvider";
import { usePalaceConfigContext } from "../../../../../services/PalaceConfigProvider";

const DEFAULT_INITIAL_DATA = {
	time: new Date().getTime(),
	blocks: [
		{
			type: "header",
			data: {
				text: "New Note",
				level: 1,
			},
		},
	],
};

function Note({ id: elementId, noteId = undefined }) {
	const { accessToken } = useAccessToken();
	const { updatePalaceDataWithSave, findElementById } =
		usePalaceConfigContext();

	const editorInstance = useRef(null);
	const [holderId] = useState(() => `editorjs-${uuidv4()}`);
	const [data, setData] = useState(DEFAULT_INITIAL_DATA);
	const didMountRef = useRef(false);
	const [isEditorReady, setIsEditorReady] = useState(false);

	// Use ref to persist the current noteId
	const noteIdRef = useRef(noteId);

	useEffect(() => {
		const fetchNoteData = async () => {
			if (!noteIdRef.current) return;

			try {
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_BASE_URL}/thought-palace/get-note?noteId=${noteId}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${accessToken}`,
						},
					},
				);

				if (response.ok) {
					const responseData = await response.json();
					setData(responseData.content || DEFAULT_INITIAL_DATA);
				} else {
					console.error("Failed to fetch note data");
				}
			} catch (error) {
				console.error("Error fetching note data:", error);
			}
		};

		fetchNoteData();

		if (!didMountRef.current) {
			initEditor();
			didMountRef.current = true;
		}

		return () => {
			editorInstance?.current?.destroy();
			editorInstance.current = null;
		};
	}, []);

	useEffect(() => {
		// Only update editor data once the editor instance is ready
		if (isEditorReady && editorInstance.current) {
			editorInstance.current.render(data);
		}
	}, [data, isEditorReady]);

	const initEditor = () => {
		const debouncedOnChange = debounce(async () => {
			if (editorInstance.current) {
				const content = await editorInstance.current.saver.save();
				saveNoteData(content);
			}
		}, 100);

		const editor = new EditorJS({
			holder: holderId,
			onReady: () => {
				editorInstance.current = editor;
				editor.ui.nodes.wrapper.classList.add("codex-editor--narrow");
				setIsEditorReady(true);
			},
			data: data,
			onChange: debouncedOnChange,
			tools: {
				header: Header,
				paragraph: {
					class: Paragraph,
					inlineToolbar: false,
					config: {
						preserveBlank: true,
					},
				},
			},
		});
	};

	const saveNoteData = async (content) => {
		try {
			const noteData = {
				_id: noteIdRef.current,
				content: content,
			};

			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_BASE_URL}/thought-palace/save-note`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${accessToken}`,
					},
					body: JSON.stringify({ noteData }),
				},
			);

			if (!response.ok) {
				console.error("Failed to save note");
			} else {
				if (!noteIdRef.current) {
					const data = await response.json();
					noteIdRef.current = data.note_id;

					updatePalaceDataWithSave((draft) => {
						const noteElement = findElementById(draft, elementId);
						noteElement.noteId = data.note_id;
					});
				}
			}
		} catch (error) {
			console.error("Error saving note data:", error);
		}
	};

	const handleKeyDown = (event) => {
		event.stopPropagation();
	};
	const handleMouseDown = (event) => {
		// Prevent selection if the mousedown occurs on an empty editor
		if (event.target.id === holderId) {
			event.preventDefault();
		}
	};

	return (
		<div className="Note" onKeyDown={handleKeyDown}>
			<div
				id={holderId}
				className="Note-editor"
				onMouseDown={handleMouseDown}
			></div>
		</div>
	);
}

export default Note;
