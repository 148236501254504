import React from "react";
import "./DialogTitle.css";

function DialogTitle({ children }) {
	return (
		<div className="DialogTitle" style={{ color: "white" }}>
			{children}
		</div>
	);
}

export default DialogTitle;
