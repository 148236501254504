import "./Ability.css";
import React, { useState } from "react";
import { useAccessToken } from "../../../../services/AccessTokenProvider";

function Ability(props) {
	const [isHovered, setIsHovered] = useState(false);
	const fileServerUrl = "http://localhost:8080";
	const imageUrl = `${fileServerUrl}/abilities/${props.identifier}.png`;
	const { accessToken } = useAccessToken();

	const unEquipItem = async () => {
		const payload = {
			equip: false,
		};
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_BASE_URL}/abilities/toggle-equip/${props.id}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${accessToken}`,
					},
					body: JSON.stringify(payload),
				},
			);

			if (!response.ok) {
				// Handle non-2xx status codes
				const errorData = await response.json();
				console.error("Un-equip failed:", errorData.message);
			} else {
				// Handle successful equip
				props.updateAbility(props.id, { is_equipped: false });
			}
		} catch (error) {
			console.error(
				"There was a problem with the unequip item operation",
				error,
			);
			// Optionally, display error to the user
		}
	};

	return (
		<div
			className="Ability"
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			{isHovered ? (
				<div className="HoverButtons">
					<button className="Ability-propertyButton">
						Properties
					</button>
					<button
						className="Ability-unequipButton"
						onClick={() => unEquipItem()}
					>
						Un-equip
					</button>
				</div>
			) : (
				<img src={imageUrl} className="Ability-coverImage" alt="" />
			)}
		</div>
	);
}

export default Ability;
