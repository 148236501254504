import "./SignupButton.css";
import { useAuth0 } from "@auth0/auth0-react";

function SignupButton() {
	const { loginWithRedirect } = useAuth0();

	return (
		<button className="SignupButton" onClick={() => loginWithRedirect()}>
			Sign up
		</button>
	);
}

export default SignupButton;
