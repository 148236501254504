import "./ThoughtPalaceApp.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Page from "./Page/Page";
import ThoughtPalace from "./views/ThoughtPalace/ThoughtPalace";
import Abilities from "./views/Abilities/Abilities";
import Payments from "./views/Payments/Payments";
import Palace from "./views/Palace/Palace";
import { ThemeContextProvider } from "../services/ThemeProvider";
import { ThemeProvider } from "@mui/material/styles";
import materialUiTheme from "../services/materialUiTheme";

function App() {
	return (
		<Page>
			<Routes>
				<Route path="/" element={<ThoughtPalace />} />
				<Route path="/abilities" element={<Abilities />} />
				<Route
					path="/palace"
					element={
						<ThemeContextProvider>
							<ThemeProvider theme={materialUiTheme}>
								<Palace />
							</ThemeProvider>
						</ThemeContextProvider>
					}
				/>
				<Route path="/payments" element={<Payments />} />
			</Routes>
		</Page>
	);
}

export default App;
