import "./Abilities.css";
import Ability from "./Ability/Ability";
import { useEffect, useState } from "react";
import AbilityShop from "./AbilityShop/AbilityShop";
import { useAccessToken } from "../../../services/AccessTokenProvider";
import ShopItem from "./AbilityShop/ShopItem/ShopItem";

function Abilities(props) {
	const [showAbilityShop, setShowAbilityShop] = useState(false);
	const [abilities, setAbilities] = useState([]);
	const { accessToken } = useAccessToken();

	const updateAbility = (abilityId, newAttributes) => {
		setAbilities(
			abilities.map((ability) => {
				if (ability.id === abilityId) {
					return { ...ability, ...newAttributes };
				}
				return ability;
			}),
		);
	};

	useEffect(() => {
		async function fetchData() {
			const abilities = await fetchAbilities();
			const userAbilities = await fetchUserAbilities();

			var combined = combineAbilities(abilities, userAbilities);

			setAbilities(combined);
		}

		if (accessToken) {
			fetchData();
		}
	}, [accessToken]);

	const fetchAbilities = async () => {
		try {
			const response = await fetch(
				"http://localhost:5001/abilities/abilities",
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${accessToken}`,
					},
				},
			);

			return await response.json();
		} catch (error) {
			console.error(
				"There was a problem with the fetch operation:",
				error,
			);
		}
	};

	const fetchUserAbilities = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_BASE_URL}/abilities/my/abilities`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${accessToken}`,
					},
				},
			);

			return await response.json();
		} catch (error) {
			console.error("Error fetching user abilities:", error);
		}
	};

	function combineAbilities(abilities, userAbilities) {
		return abilities.map((ability) => {
			const userAbility = userAbilities.find(
				(ua) => ua.ability_id === ability.id,
			);
			return {
				...ability,
				is_owned: !!userAbility,
				is_equipped: userAbility ? userAbility.is_equipped : false,
			};
		});
	}

	const toggleAbilityShop = () => {
		setShowAbilityShop(!showAbilityShop);
	};

	const renderEquippedAndOwnedAbilities = () => {
		return abilities
			.filter((ability) => ability.is_equipped && ability.is_owned)
			.map((ability, index) => (
				<Ability
					key={index}
					{...ability}
					updateAbility={updateAbility}
				/>
			));
	};

	return (
		<div className="Abilities">
			<div className="Abilities-container">
				{renderEquippedAndOwnedAbilities()}
				<button
					className="Abilities-openShop"
					onClick={toggleAbilityShop}
				>
					+
				</button>
			</div>
			{showAbilityShop && (
				<AbilityShop
					abilities={abilities}
					updateAbility={updateAbility}
					onClose={toggleAbilityShop}
				/>
			)}
		</div>
	);
}

export default Abilities;
