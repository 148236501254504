import React from "react";
import { Button } from "@mui/material";
import "./ApplyConfigButton.css";

function ApplyConfigButton({ handleApply }) {
	return (
		<Button className="ApplyConfigButton" onClick={handleApply}>
			Apply
		</Button>
	);
}

export default ApplyConfigButton;
