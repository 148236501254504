import React, { useState, useEffect } from "react";
import "./Archive.css";
import File from "./File/File";
import { useAccessToken } from "../../../../../services/AccessTokenProvider";
import TextEditor from "../../../../../TextEditor/TextEditor";

function Archive({ id }) {
	const { accessToken } = useAccessToken();
	const [fileEntries, setFileEntries] = useState([]);
	const [isDragging, setIsDragging] = useState(false);
	const [editingFile, setEditingFile] = useState(null);

	useEffect(() => {
		const fetchFiles = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_BASE_URL}/thought-palace/files/${id}`,
					{
						method: "GET",
						headers: {
							Authorization: `Bearer ${accessToken}`,
						},
					},
				);

				if (response.ok) {
					const data = await response.json();
					setFileEntries(data.files);
				} else {
					console.error("Failed to fetch files:", response.status);
				}
			} catch (error) {
				console.error("Error fetching files:", error);
			}
		};

		fetchFiles();
	}, [id, accessToken]);

	const fetchFileContent = async (fileId) => {
		const response = await fetch(
			`${process.env.REACT_APP_BACKEND_BASE_URL}/thought-palace/file-content?fileId=${fileId}`,
			{
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			},
		);
		const data = await response.json();
		setEditingFile({ id: fileId, content: data.content });
	};

	const saveFileContent = async (content) => {
		await fetch(
			`${process.env.REACT_APP_BACKEND_BASE_URL}/thought-palace/file-content`,
			{
				method: "POST",
				headers: {
					Authorization: `Bearer ${accessToken}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ fileId: editingFile.id, content }),
			},
		);
		setFileEntries((prev) =>
			prev.map((file) =>
				file.id === editingFile.id ? { ...file, content } : file,
			),
		);
		setEditingFile(null);
	};

	const handleDragOver = (e) => {
		e.preventDefault();
		e.stopPropagation();
		e.dataTransfer.dropEffect = "copy";
		setIsDragging(true);
	};

	const handleDragLeave = (e) => {
		e.stopPropagation();
		setIsDragging(false);
	};

	const handleDrop = async (e) => {
		e.preventDefault();
		e.stopPropagation();
		setIsDragging(false);
		const files = Array.from(e.dataTransfer.files);

		const newFileEntries = [];
		for (const file of files) {
			try {
				const formData = new FormData();
				formData.append("file", file);
				formData.append("archiveId", id);

				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_BASE_URL}/thought-palace/upload-file`,
					{
						method: "POST",
						headers: {
							Authorization: `Bearer ${accessToken}`,
						},
						body: formData,
					},
				);

				if (response.ok) {
					const data = await response.json();
					newFileEntries.push({
						id: data.id,
						name: data.name,
						path: data.path,
					});
				} else {
					console.error(
						"File upload failed with status:",
						response.status,
					);
				}
			} catch (error) {
				console.error("Error uploading file:", error);
			}
		}
		setFileEntries((prev) => [...prev, ...newFileEntries]);
	};

	const handleDelete = async (fileId) => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_BASE_URL}/thought-palace/delete-file?archiveId=${id}&fileId=${fileId}`,
				{
					method: "DELETE",
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				},
			);

			if (response.ok) {
				setFileEntries((prev) =>
					prev.filter((file) => file.id !== fileId),
				);
			} else {
				console.error("Failed to delete file:", response.status);
			}
		} catch (error) {
			console.error("Error deleting file:", error);
		}
	};

	return (
		<div
			className={`Archive ${isDragging ? "dragging" : ""}`}
			onDragOver={handleDragOver}
			onDragLeave={handleDragLeave}
			onDrop={handleDrop}
		>
			<div className="FileList">
				{fileEntries.map((file) => (
					<File
						key={file.id}
						name={file.name}
						onEdit={() => fetchFileContent(file.id)}
						onDelete={() => handleDelete(file.id)}
					/>
				))}
			</div>
			{editingFile && (
				<TextEditor
					fileContent={editingFile.content}
					onSave={saveFileContent}
					onClose={() => setEditingFile(null)}
				/>
			)}
		</div>
	);
}

export default Archive;
