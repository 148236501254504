import React, { useEffect, useRef, useState } from "react";
import "./DataTable.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditableCell from "../../../../utils/customReusableComponents/EditableCell/EditableCell";
import { useAccessToken } from "../../../../../services/AccessTokenProvider";
import { useThemeContext } from "../../../../../services/ThemeProvider";

function DataTable({ tableName }) {
	const { getThemeForComponent } = useThemeContext();
	const currentTheme = getThemeForComponent("dataTable");

	const { accessToken } = useAccessToken();
	const [columns, setColumns] = useState(null);
	const [rows, setRows] = useState(null);
	const [isAnyRowSelected, setIsAnyRowSelected] = useState(false);
	const [isEditMode, setIsEditMode] = useState(false);
	const [isAddingRow, setIsAddingRow] = useState(false);

	const originalSelectedRows = useRef({});

	useEffect(() => {
		const fetchTableInfo = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_BASE_URL}/thought-palace/get-table-info?tableName=${tableName}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${accessToken}`,
						},
					},
				);
				if (response.ok) {
					const data = await response.json();
					setColumns(data.response.columns);

					// Fetch table data only after columns are set
					await fetchTableData();
				}
			} catch (error) {
				console.error("Failed to fetch tables:", error);
			}
		};

		const fetchTableData = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_BASE_URL}/thought-palace/get-table-data?tableName=${tableName}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${accessToken}`,
						},
					},
				);
				if (response.ok) {
					const data = await response.json();
					setRows(data.response);
				}
			} catch (error) {
				console.error("Failed to fetch tables:", error);
			}
		};

		fetchTableInfo();
	}, [tableName, accessToken]);

	const handleCellChange = (newValue, rowId, columnName) => {
		const updatedRows = rows.map((row) =>
			row._id === rowId ? { ...row, [columnName]: newValue } : row,
		);
		setRows(updatedRows);
	};

	const handleRowClick = (rowId) => {
		if (!isEditMode && !isAddingRow) {
			const updatedRows = rows.map((row) =>
				row._id === rowId
					? { ...row, isSelected: !row.isSelected }
					: row,
			);
			setRows(updatedRows);
			setIsAnyRowSelected(updatedRows.some((row) => row.isSelected));
		}
	};

	const createEmptyRow = () => {
		const newRow = columns.reduce(
			(acc, column) => ({ ...acc, [column.column_name]: "" }),
			{
				isSelected: false,
				isEditing: true,
				tempId: `temp-${Math.random()}`, // Temporary unique key
			},
		);
		return newRow;
	};

	const handleEditClick = () => {
		originalSelectedRows.current = rows.reduce((acc, row) => {
			if (row.isSelected) {
				acc[row._id] = { ...row };
			}
			return acc;
		}, {});

		const updatedRows = rows.map((row) =>
			row.isSelected
				? { ...row, isEditing: true, isSelected: false }
				: { ...row, isSelected: false },
		);
		setRows(updatedRows);
		setIsAnyRowSelected(false);
		setIsEditMode(true);
	};

	const handleSaveEditMode = async () => {
		const newRow = rows.find((row) => !row._id && row.isEditing);
		const editedRows = rows.filter((row) => row._id && row.isEditing);

		try {
			// Handle creation of the new row
			if (newRow) {
				const filteredNewRow = filterRowByColumns(newRow, columns);

				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_BASE_URL}/thought-palace/create-table-row`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${accessToken}`,
						},
						body: JSON.stringify({
							tableName,
							newRow: filteredNewRow,
						}),
					},
				);

				if (response.ok) {
					const data = await response.json();
					const createdRow = data.response;
					// Replace the frontend row with the one returned from the backend (including the new _id)
					setRows((prevRows) =>
						prevRows.map((row) =>
							row === newRow
								? { ...createdRow, isEditing: false }
								: row,
						),
					);
				} else {
					throw new Error("Failed to create row.");
				}
			}

			// Handle the update of edited rows
			if (editedRows.length > 0) {
				const filteredEditedRows = editedRows.map((row) =>
					filterRowByColumns(row, columns),
				);

				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_BASE_URL}/thought-palace/update-table-rows`,
					{
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${accessToken}`,
						},
						body: JSON.stringify({
							tableName,
							rows: filteredEditedRows,
						}),
					},
				);

				if (response.ok) {
					// Update the rows in the state with the data returned from the backend
					setRows((prevRows) =>
						prevRows.map((row) => ({
							...row,
							isEditing: false,
						})),
					);
				} else {
					throw new Error("Failed to update rows.");
				}
			}

			// After all operations are done, exit edit mode
			setIsEditMode(false);
			setIsAddingRow(false);
		} catch (error) {
			console.error("Failed to save rows:", error);
		}
	};

	const handleCancelEditMode = () => {
		const updatedRows = rows
			.filter(
				(row) =>
					!row.isEditing || originalSelectedRows.current[row._id],
			) // If a row was added it gets deleted by this
			.map((row) => ({
				...(originalSelectedRows.current[row._id] || row), // Restore data from originalSelectedRows if it exists
				isEditing: false,
				isSelected: false,
			}));
		setRows(updatedRows);
		setIsEditMode(false);
		setIsAddingRow(false);
	};

	const handleAddRow = () => {
		const newRow = createEmptyRow();

		const updatedRows = rows.map((row) => ({
			...row,
			isSelected: false,
		}));
		setRows([newRow, ...updatedRows]);
		setIsEditMode(true);
		setIsAddingRow(true);
	};

	const handleDeleteSelected = async () => {
		// Get the IDs of the selected rows
		const selectedRowIds = rows
			.filter((row) => row.isSelected)
			.map((row) => row._id);

		try {
			// Send a DELETE request to the backend with the selected row IDs
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_BASE_URL}/thought-palace/delete-table-rows`,
				{
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${accessToken}`,
					},
					body: JSON.stringify({
						tableName,
						rowIds: selectedRowIds,
					}),
				},
			);

			if (response.ok) {
				// If the delete request was successful, filter out the deleted rows from the state
				const updatedRows = rows.filter((row) => !row.isSelected);
				setRows(updatedRows);
				setIsAnyRowSelected(false);
			} else {
				throw new Error("Failed to delete selected rows.");
			}
		} catch (error) {
			console.error("Failed to delete rows:", error);
		}
	};

	const filterRowByColumns = (row, columns) => {
		// Extract the column names from the columns array
		const allowedKeys = columns.map((column) => column.column_name);

		// Add '_id' to the allowed keys if it exists in the row (for updates)
		if (row._id) {
			allowedKeys.push("_id");
		}

		// Create a new object with only the allowed keys
		const filteredRow = Object.keys(row).reduce((acc, key) => {
			if (allowedKeys.includes(key)) {
				acc[key] = row[key];
			}
			return acc;
		}, {});

		return filteredRow;
	};

	const actionColumnWidth = 70; // Fixed width for the action column
	const columnWidth = columns
		? `calc((100% - ${actionColumnWidth}px) / ${columns.length})`
		: "auto";

	return (
		<div
			className="DataTable"
			style={{
				backgroundColor: currentTheme.container.backgroundColor,
			}}
		>
			<TableContainer
				sx={{
					width: "100%",
					height: "100%",
					"&::-webkit-scrollbar": {
						width: 8,
						height: 5,
					},
					"&::-webkit-scrollbar-track": {
						backgroundColor: currentTheme.container.trackColor,
					},
					"&::-webkit-scrollbar-thumb": {
						backgroundColor: currentTheme.container.scrollbarColor,
						borderRadius: "2px",
					},
					"&::-webkit-scrollbar-thumb:hover": {
						backgroundColor:
							currentTheme.container.scrollbarHoverColor,
					},
				}}
			>
				{columns ? (
					<Table
						size="small"
						stickyHeader
						sx={{ tableLayout: "fixed" }}
					>
						<TableHead>
							<TableRow>
								{columns.map((column) => (
									<TableCell
										key={column.column_name}
										align={"left"}
										sx={{
											width: columnWidth,
											padding: "8px",
											backgroundColor:
												currentTheme.table.header
													.backgroundColor,
											color: currentTheme.table.header
												.color,
										}}
									>
										{column.display_name}
									</TableCell>
								))}
								<TableCell
									sx={{
										width: actionColumnWidth,
										padding: "3px",
										textAlign: "center",
										backgroundColor:
											currentTheme.table.header
												.backgroundColor,
									}}
								>
									<div
										style={{
											display: "flex",
											gap: "0px",
											justifyContent: "right",
											alignItems: "center",
										}}
									>
										{isEditMode && (
											<>
												<IconButton
													size="small"
													sx={{ padding: "2px" }}
													onClick={
														handleCancelEditMode
													}
												>
													<CloseIcon
														sx={{
															color: currentTheme
																.iconButton
																.color,
															fontSize: "20px",
															stroke: currentTheme
																.iconButton
																.color,
															strokeWidth: 1,
														}}
													/>
												</IconButton>
												<IconButton
													size="small"
													sx={{ padding: "2px" }}
													onClick={handleSaveEditMode}
												>
													<CheckIcon
														sx={{
															color: currentTheme
																.iconButton
																.color,
															fontSize: "20px",
															stroke: currentTheme
																.iconButton
																.color,
															strokeWidth: 1,
														}}
													/>
												</IconButton>
											</>
										)}
										{!isEditMode && (
											<>
												{isAnyRowSelected && (
													<IconButton
														size="small"
														sx={{ padding: "2px" }}
														onClick={
															handleDeleteSelected
														}
													>
														<DeleteIcon
															sx={{
																color: currentTheme
																	.iconButton
																	.color,
																fontSize:
																	"20px",
															}}
														/>
													</IconButton>
												)}
												{isAnyRowSelected && (
													<IconButton
														size="small"
														sx={{ padding: "2px" }}
														onClick={
															handleEditClick
														}
													>
														<EditIcon
															sx={{
																color: currentTheme
																	.iconButton
																	.color,
																fontSize:
																	"20px",
															}}
														/>
													</IconButton>
												)}
												<IconButton
													size="small"
													sx={{ padding: "2px" }}
													onClick={handleAddRow}
												>
													<AddIcon
														sx={{
															color: currentTheme
																.iconButton
																.color,
															fontSize: "20px",
															stroke: currentTheme
																.iconButton
																.color,
															strokeWidth: 1,
														}}
													/>
												</IconButton>
											</>
										)}
									</div>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{rows &&
								rows.map((row) => (
									<TableRow
										key={row._id || row.tempId} // tempId only for newly created row which has no _id
										onClick={() => handleRowClick(row._id)}
										sx={{
											height: "48px",
											backgroundColor: row.isSelected
												? currentTheme.table.row
														.isSelectedColor
												: currentTheme.table.row
														.backgroundColor,
											cursor: isEditMode
												? "default"
												: "pointer",
										}}
									>
										{columns.map((column) => (
											<EditableCell
												key={`${row._id}-${column.column_name}`}
												value={row[column.column_name]}
												columnId={column.column_name}
												type={column.type}
												options={column.options}
												rowId={row._id}
												isAlwaysEditable={row.isEditing}
												onChange={handleCellChange}
												sx={{
													padding: "8px",
													height: "48px",
													boxSizing: "border-box",
													maxWidth: columnWidth,
													minWidth: columnWidth,
													color: currentTheme.table
														.row.color,
												}}
												displayColor={
													currentTheme.editableCell
														.displayColor
												}
												inputColor={
													currentTheme.editableCell
														.inputColor
												}
												secondaryInputColor={
													currentTheme.editableCell
														.secondaryInputColor
												}
											/>
										))}
										<TableCell
											sx={{
												textAlign: "center",
												padding: "8px",
											}}
										>
											{/* This cell is now used for row selection only, icons are moved to header */}
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				) : (
					<div>Loading...</div> // Render a loading indicator until columns are available
				)}
			</TableContainer>
		</div>
	);
}

export default DataTable;
