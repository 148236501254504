import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import "./File.css";

function File({ name, onDelete, onEdit }) {
	return (
		<div className="File">
			<span className="FileName">{name}</span>
			<div className="FileActions">
				<EditIcon
					fontSize="small"
					className="FileActionButton"
					onClick={onEdit}
				/>
				<DeleteIcon
					fontSize="small"
					className="FileActionButton"
					onClick={onDelete}
				/>
			</div>
		</div>
	);
}

export default File;
