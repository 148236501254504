import React, { useState, useRef } from "react";
import "./ThoughtInput.css";
import sendIcon from "../../../../resources/up-arrow.png";
import recordIcon from "../../../../resources/record.png";
import { useAccessToken } from "../../../../services/AccessTokenProvider";

function ThoughtInput(props) {
	const [userPrompt, setUserPrompt] = useState("");
	const textContent = useRef(null);
	const { accessToken } = useAccessToken();

	async function handleSendUserPrompt() {
		// Clear existing text content
		textContent.current.innerText = "";

		const payload = {
			user_prompt: userPrompt,
		};

		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_BASE_URL}/thought-palace/execute-userprompt`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${accessToken}`,
					},
					body: JSON.stringify(payload),
				},
			);

			const data = await response.json();

			props.showResponse(data.response);
		} catch (error) {
			console.error("Error sending user prompt:", error);
		}
	}

	const handleKeyDown = (e) => {
		if (e.key === "Enter" && !e.shiftKey) {
			e.preventDefault();
			handleSendUserPrompt();
		}
	};

	return (
		<div className="ThoughtInput">
			<button className="ThoughtInput-speech">
				<img src={recordIcon} className="ThoughtInput-icon" alt="" />
			</button>
			<div
				className="ThoughtInput-text"
				contentEditable="true"
				placeholder="Command me..."
				onKeyDown={handleKeyDown}
				ref={textContent}
				onInput={(e) => setUserPrompt(e.target.innerText)}
			/>
			<button className="ThoughtInput-send">
				<img
					src={sendIcon}
					className="ThoughtInput-icon"
					alt=""
					onClick={handleSendUserPrompt}
				/>{" "}
			</button>
		</div>
	);
}

export default ThoughtInput;
