import {
	Home as HomeIcon,
	Star as StarIcon,
	Settings as SettingsIcon,
	Person as PersonIcon,
	Search as SearchIcon,
	AccessTime as AccessTimeIcon,
	AccountCircle as AccountCircleIcon,
	Add as AddIcon,
	Assignment as AssignmentIcon,
	Bookmark as BookmarkIcon,
	CalendarToday as CalendarTodayIcon,
	Category as CategoryIcon,
	Chat as ChatIcon,
	CheckCircle as CheckCircleIcon,
	ChevronRight as ChevronRightIcon,
	CloudUpload as CloudUploadIcon,
	Comment as CommentIcon,
	Dashboard as DashboardIcon,
	Delete as DeleteIcon,
	Description as DescriptionIcon,
	Edit as EditIcon,
	Event as EventIcon,
	ExitToApp as ExitToAppIcon,
	FileCopy as FileCopyIcon,
	Folder as FolderIcon,
	FormatListBulleted as FormatListBulletedIcon,
	Image as ImageIcon,
	Inbox as InboxIcon,
	Info as InfoIcon,
	InsertDriveFile as InsertDriveFileIcon,
	Label as LabelIcon,
	LibraryBooks as LibraryBooksIcon,
	Link as LinkIcon,
	ListAlt as ListAltIcon,
	Lock as LockIcon,
	Menu as MenuIcon,
	Note as NoteIcon,
	Notifications as NotificationsIcon,
	OpenInNew as OpenInNewIcon,
	PhotoCamera as PhotoCameraIcon,
	Publish as PublishIcon,
	Save as SaveIcon,
	Send as SendIcon,
	Share as ShareIcon,
	TextFields as TextFieldsIcon,
	Today as TodayIcon,
	Visibility as VisibilityIcon,
	Warning as WarningIcon,
	Web as WebIcon,
	ZoomIn as ZoomInIcon,
	SpaceDashboard,
} from "@mui/icons-material";

const icons = [
	{ component: SpaceDashboard, name: "Dashboard" },
	{ component: HomeIcon, name: "HomeIcon" },
	{ component: StarIcon, name: "StarIcon" },
	{ component: SettingsIcon, name: "SettingsIcon" },
	{ component: PersonIcon, name: "PersonIcon" },
	{ component: SearchIcon, name: "SearchIcon" },
	{ component: AccessTimeIcon, name: "AccessTimeIcon" },
	{ component: AccountCircleIcon, name: "AccountCircleIcon" },
	{ component: AddIcon, name: "AddIcon" },
	{ component: AssignmentIcon, name: "AssignmentIcon" },
	{ component: BookmarkIcon, name: "BookmarkIcon" },
	{ component: CalendarTodayIcon, name: "CalendarTodayIcon" },
	{ component: CategoryIcon, name: "CategoryIcon" },
	{ component: ChatIcon, name: "ChatIcon" },
	{ component: CheckCircleIcon, name: "CheckCircleIcon" },
	{ component: ChevronRightIcon, name: "ChevronRightIcon" },
	{ component: CloudUploadIcon, name: "CloudUploadIcon" },
	{ component: CommentIcon, name: "CommentIcon" },
	{ component: DashboardIcon, name: "DashboardIcon" },
	{ component: DeleteIcon, name: "DeleteIcon" },
	{ component: DescriptionIcon, name: "DescriptionIcon" },
	{ component: EditIcon, name: "EditIcon" },
	{ component: EventIcon, name: "EventIcon" },
	{ component: ExitToAppIcon, name: "ExitToAppIcon" },
	{ component: FileCopyIcon, name: "FileCopyIcon" },
	{ component: FolderIcon, name: "FolderIcon" },
	{ component: FormatListBulletedIcon, name: "FormatListBulletedIcon" },
	{ component: ImageIcon, name: "ImageIcon" },
	{ component: InboxIcon, name: "InboxIcon" },
	{ component: InfoIcon, name: "InfoIcon" },
	{ component: InsertDriveFileIcon, name: "InsertDriveFileIcon" },
	{ component: LabelIcon, name: "LabelIcon" },
	{ component: LibraryBooksIcon, name: "LibraryBooksIcon" },
	{ component: LinkIcon, name: "LinkIcon" },
	{ component: ListAltIcon, name: "ListAltIcon" },
	{ component: LockIcon, name: "LockIcon" },
	{ component: MenuIcon, name: "MenuIcon" },
	{ component: NoteIcon, name: "NoteIcon" },
	{ component: NotificationsIcon, name: "NotificationsIcon" },
	{ component: OpenInNewIcon, name: "OpenInNewIcon" },
	{ component: PhotoCameraIcon, name: "PhotoCameraIcon" },
	{ component: PublishIcon, name: "PublishIcon" },
	{ component: SaveIcon, name: "SaveIcon" },
	{ component: SendIcon, name: "SendIcon" },
	{ component: ShareIcon, name: "ShareIcon" },
	{ component: TextFieldsIcon, name: "TextFieldsIcon" },
	{ component: TodayIcon, name: "TodayIcon" },
	{ component: VisibilityIcon, name: "VisibilityIcon" },
	{ component: WarningIcon, name: "WarningIcon" },
	{ component: WebIcon, name: "WebIcon" },
	{ component: ZoomInIcon, name: "ZoomInIcon" },
];

export default icons;
