import React, { useState, useEffect } from "react";
import {
	TableCell,
	TextField,
	Checkbox,
	Select,
	MenuItem,
} from "@mui/material";

function EditableCell({
	sx,
	value: initialValue,
	onChange,
	columnId,
	rowId,
	type = "text",
	options = [],
	isAlwaysEditable = false,
	displayColor,
	inputColor,
	secondaryInputColor,
}) {
	const [isEditing, setIsEditing] = useState(isAlwaysEditable);
	const [localValue, setLocalValue] = useState(initialValue);

	useEffect(() => {
		setIsEditing(isAlwaysEditable);
		setLocalValue(initialValue);
	}, [isAlwaysEditable, initialValue]);

	const handleBlur = () => {
		if (localValue !== initialValue) {
			onChange(localValue, rowId, columnId);
		}
		if (!isAlwaysEditable) {
			setIsEditing(false);
		}
	};

	const handleChange = (e) => {
		const newValue = type === "boolean" ? e.target.checked : e.target.value;
		setLocalValue(newValue);
	};

	return (
		<TableCell
			sx={[
				{
					boxSizing: "border-box",
					wordBreak: "break-word",
					overflowWrap: "break-word",
					color: displayColor || "inherit",
				},
				sx,
			]}
		>
			{isEditing ? (
				type === "boolean" ? (
					<Checkbox
						checked={localValue}
						onChange={handleChange}
						onBlur={handleBlur}
						sx={{
							padding: 0,
							color: inputColor || "inherit",
							"&.Mui-checked": {
								color: inputColor || "inherit",
							},
						}}
					/>
				) : type === "select" && options.length > 0 ? (
					<Select
						value={localValue}
						onChange={handleChange}
						onBlur={handleBlur}
						variant="standard"
						fullWidth
						sx={{
							margin: 0,
							padding: 0,
							fontSize: "inherit",
							color: inputColor || "inherit", // Sets the text color of the selected item in the input
							"& .MuiSelect-select": {
								color: inputColor || "inherit", // Ensures the selected text inside the select has the right color
							},
							"& .MuiSvgIcon-root": {
								color: inputColor || "inherit", // Colors the dropdown arrow icon
							},

							"&.MuiInput-underline:before": {
								borderBottomColor: inputColor || "inherit",
							},
							"&.MuiInput-underline:after": {
								borderBottomColor: inputColor || "inherit",
							},
							"&:hover.MuiInput-underline:before": {
								borderBottomColor:
									secondaryInputColor || "inherit", // Color of the underline on hover
							},
						}}
					>
						{options.map((option) => (
							<MenuItem key={option} value={option}>
								{option}
							</MenuItem>
						))}
					</Select>
				) : (
					<TextField
						value={localValue}
						onChange={handleChange}
						onBlur={handleBlur}
						variant="standard"
						fullWidth
						sx={{
							margin: 0,
							padding: 0,
							fontSize: "inherit",
							"& .MuiInputBase-input": {
								color: inputColor || "inherit", // Sets the text color of the input field
							},
							"& .MuiInput-underline:before": {
								borderBottomColor: inputColor || "inherit",
							},
							"& .MuiInput-underline:after": {
								borderBottomColor: inputColor || "inherit",
							},
							"&:hover .MuiInput-underline:before": {
								borderBottomColor:
									secondaryInputColor || "inherit", // Color of the underline on hover
							},
						}}
						inputProps={{
							style: { padding: "0 0" },
						}}
					/>
				)
			) : type === "boolean" ? (
				<Checkbox
					checked={initialValue}
					disabled
					sx={{
						padding: 0,
						"&.MuiCheckbox-root.Mui-disabled": {
							color: displayColor || "inherit",
						},
					}}
				/>
			) : (
				initialValue
			)}
		</TableCell>
	);
}

export default EditableCell;
