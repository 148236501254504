import './UserMenu.css'
import userIcon from '../../../../resources/user.png';
import {useState} from "react";
import {useAuth0} from "@auth0/auth0-react";

function UserMenu (props) {
    const [showMenu, setShowMenu] = useState(false);
    const { logout } = useAuth0();
    const { user, isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return <div>Loading ...</div>;
    }

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    return (
        <div className="UserMenu">
            {showMenu && (
                <div className="UserMenu-menu">
                    <button className="UserMenu-menuButton">Payments</button>
                    <button className="UserMenu-menuButton">User Settings</button>
                    <button className="UserMenu-menuButton" onClick={() => logout({ logoutParams: { returnTo: window.location.origin }})}>Logout</button>
                </div>
            )}
            <button className="UserMenu-userItem" onClick={toggleMenu}>
                <img className="UserMenu-icon" src={userIcon} alt="User"/>
            </button>
        </div>
    )
}

export default UserMenu;