import React from "react";
import Switch from "@mui/material/Switch";
import "./DialogSwitch.css";

function DialogSwitch({ label, checked, onChange }) {
	return (
		<div className="DialogSwitch">
			<span className="DialogSwitch-label" style={{ color: "#9e9e9e" }}>
				{label}
			</span>
			<div
				className="DialogSwitch-line"
				style={{ color: "#9e9e9e" }}
			></div>
			<Switch checked={checked} onChange={onChange} />
		</div>
	);
}

export default DialogSwitch;
