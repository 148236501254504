import { createTheme } from "@mui/material/styles";

const materialUiTheme = createTheme({
	palette: {
		primary: {
			main: "#2c3e50",
		},
		secondary: {
			main: "#520003",
		},
		customGray: {
			main: "#b0bec5", // Custom color
			contrastText: "#ffffff",
		},
		customWhite: {
			main: "#ffffff",
			contrastText: "#000000",
		},
		customBlack: {
			main: "#000000",
			contrastText: "#ffffff",
		},
	},
});

export default materialUiTheme;
