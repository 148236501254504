import React from "react";
import AppBar from "@mui/material/AppBar";
import MUI_Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { emphasize, styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import icons from "../../../utils/customReusableComponents/IconPicker/icons";
import {
	Home as HomeIcon,
	Edit as EditIcon,
	Clear as ClearIcon,
	Brightness4 as ThemeIcon,
} from "@mui/icons-material";

function Toolbar({
	breadcrumbs,
	toggleEditMode,
	clearPalace,
	toggleThemeMode,
	onBreadcrumbClick, // Accept the click handler
}) {
	const StyledBreadcrumb = styled(Chip)({
		backgroundColor: "#33333", // Light background color
		height: "24px", // Set height explicitly
		color: "white", // Dark text color
		fontWeight: 400, // Normal font weight
		cursor: "pointer",
		"&:hover": {
			backgroundColor: "grey",
		},
	});

	const breadcrumbsItems = breadcrumbs.map((breadcrumb) => {
		const iconObject = icons.find(
			(icon) => icon.name === breadcrumb.iconName,
		);
		const IconComponent = iconObject ? iconObject.component : null;

		return (
			<StyledBreadcrumb
				key={breadcrumb.key}
				component="div"
				label={breadcrumb.label}
				icon={
					IconComponent ? (
						<IconComponent
							fontSize="small"
							style={{ color: "#FFFFFF" }}
						/>
					) : null
				}
				onClick={() => onBreadcrumbClick(breadcrumb.id)} // Attach click handler
				sx={{
					color: "#FFFFFF",
					backgroundColor: "#333333",
					padding: "15px 5px",
					border: "1px solid white",
					cursor: "pointer",
				}}
			/>
		);
	});

	return (
		<AppBar position="static" sx={{ backgroundColor: "#2c3e50" }}>
			<MUI_Toolbar>
				{/* Breadcrumbs on the left */}
				<Breadcrumbs
					aria-label="breadcrumb"
					separator={
						<NavigateNextIcon
							fontSize="small"
							sx={{ color: "#FFFFFF" }}
						/>
					}
					sx={{ mr: 2 }}
				>
					{breadcrumbsItems}
				</Breadcrumbs>

				{/* Spacer to push buttons to the right */}
				<Box sx={{ flexGrow: 1 }} />

				{/* Icon buttons on the right */}
				<IconButton
					color="inherit"
					onClick={toggleEditMode}
					sx={{ borderRadius: "8px" }}
				>
					<EditIcon />
				</IconButton>
				<IconButton
					color="inherit"
					onClick={clearPalace}
					sx={{ borderRadius: "8px", ml: 1 }}
				>
					<ClearIcon />
				</IconButton>
				<IconButton
					color="inherit"
					onClick={toggleThemeMode}
					sx={{ borderRadius: "8px", ml: 1 }}
				>
					<ThemeIcon />
				</IconButton>
			</MUI_Toolbar>
		</AppBar>
	);
}

export default Toolbar;
