import React, { useEffect } from "react";
import DialogPickerItem from "../../../Dialog/DialogPicker/DialogPickerItem/DialogPickerItem";
import DialogPicker from "../../../Dialog/DialogPicker/DialogPicker";
import Dialog from "../../../Dialog/Dialog";

function OptionsDialog({
	onClose,
	repeatingEvent,
	isRecurring,
	onEdit,
	onDelete,
	selectedEvents,
}) {
	const handleOptionClick = (option) => {
		if (option === "edit") {
			onEdit();
		} else if (option === "all") {
			onDelete("all");
		} else if (option === "present") {
			onDelete("present");
		} else if (option === "future") {
			onDelete("future");
		}
	};

	const totalSelectedEvents = selectedEvents.includes(repeatingEvent.id)
		? selectedEvents.length
		: selectedEvents.length + 1;

	const multipleSelected = totalSelectedEvents > 1;

	return (
		<Dialog
			closable={true}
			onClose={onClose}
			width="small"
			style={{
				backgroundColor: "#333333",
			}}
		>
			{multipleSelected ? (
				<DialogPicker>
					<DialogPickerItem id="all" clickEvent={handleOptionClick}>
						Delete selected Events
					</DialogPickerItem>
				</DialogPicker>
			) : isRecurring ? (
				<DialogPicker>
					<DialogPickerItem id="edit" clickEvent={handleOptionClick}>
						Edit
					</DialogPickerItem>
					<DialogPickerItem
						id="present"
						clickEvent={handleOptionClick}
					>
						Delete this event
					</DialogPickerItem>
					<DialogPickerItem
						id="future"
						clickEvent={handleOptionClick}
					>
						Delete future Events
					</DialogPickerItem>
				</DialogPicker>
			) : (
				<DialogPicker>
					<DialogPickerItem id="edit" clickEvent={handleOptionClick}>
						Edit
					</DialogPickerItem>
					<DialogPickerItem id="all" clickEvent={handleOptionClick}>
						Delete
					</DialogPickerItem>
				</DialogPicker>
			)}
		</Dialog>
	);
}

export default OptionsDialog;
