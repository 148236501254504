// Cover.js
import React, { useState, useEffect, useRef } from "react";
import { coverConfig } from "./coverConfig";
import { Box, Typography, TextField } from "@mui/material";
import StorageIcon from "@mui/icons-material/Storage";
import "./Cover.css";
import CoverDialog from "./CoverDialog/CoverDialog";
import { usePalaceConfigContext } from "../../../../../services/PalaceConfigProvider";

const ICON_ONLY_SIZE = 3;

function Cover({ element, isEditMode, width, height }) {
	const {
		gridFieldSize,
		pushDialog,
		popDialog,
		updatePalaceData,
		findElementById,
	} = usePalaceConfigContext();
	const { icon, title: defaultTitle } = coverConfig[element.type];

	const [title, setTitle] = useState(defaultTitle);
	const [inputWidth, setInputWidth] = useState(
		ICON_ONLY_SIZE * gridFieldSize,
	);
	const spanRef = useRef(null);

	useEffect(() => {
		let title = element.title;
		if (title === undefined) {
			title = defaultTitle;
		}
		setTitle(title);
	}, []);

	useEffect(() => {
		if (spanRef.current) {
			const calculatedWidth = spanRef.current.offsetWidth + 10;
			setInputWidth(
				Math.max(calculatedWidth, ICON_ONLY_SIZE * gridFieldSize),
			);
		}
	}, [title, gridFieldSize]);

	const handleChange = (event) => {
		setTitle(event.target.value);
	};

	const handleClick = (event) => {
		event.stopPropagation();
	};

	const handleKeyDown = (event) => {
		event.stopPropagation();
	};

	const handleCoverClick = (event) => {
		if (!isEditMode) {
			event.stopPropagation();
			pushDialog(
				<CoverDialog
					onClose={popDialog}
					element={element}
					updatePalaceData={updatePalaceData}
				/>,
			);
		}
	};

	const handleBlur = () => {
		updatePalaceData((draft) => {
			const coverElement = findElementById(draft, element.id);
			coverElement.title = title;
		});
	};

	const textStyles = {
		fontSize: "1rem",
		fontWeight: 400,
		lineHeight: 1.5,
		color: "#666",
		whiteSpace: "normal",
		overflowWrap: "break-word",
		textAlign: "center",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: "100%",
	};

	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			height="100%"
			width="100%"
			position="relative"
			className="Cover"
			onClick={handleCoverClick}
		>
			{/* Centered Icon */}
			<Box component="span" className="Cover-icon">
				{icon || <StorageIcon fontSize="large" />}
			</Box>

			{/* Invisible span for measuring text width */}
			<span
				ref={spanRef}
				style={{
					...textStyles,
					visibility: "hidden",
					position: "absolute",
					whiteSpace: "nowrap",
				}}
			>
				{title}
			</span>

			{/* Centered Title Below Icon */}
			{width > ICON_ONLY_SIZE && height > ICON_ONLY_SIZE && (
				<Box
					position="absolute"
					left="50%"
					top="calc(50% + 10px)"
					style={{
						transform: "translateX(-50%)",
						textAlign: "center",
						width: "100%",
					}}
				>
					{isEditMode ? (
						<TextField
							value={title}
							onChange={handleChange}
							onBlur={handleBlur}
							variant="standard"
							InputProps={{
								disableUnderline: false,
								style: textStyles,
							}}
							inputProps={{
								style: {
									textAlign: "center",
								},
								maxLength: 15,
							}}
							style={{
								pointerEvents: "auto",
								width: `${inputWidth}px`,
								minWidth: ICON_ONLY_SIZE * gridFieldSize + "px",
								maxWidth: gridFieldSize * width - 20 + "px",
							}}
							onClick={handleClick}
							onMouseDown={handleClick}
							onKeyDown={handleKeyDown}
						/>
					) : (
						<Typography
							variant="subtitle1"
							className="title"
							style={{
								...textStyles,
								marginTop: "14px",
								width: "100%",
								pointerEvents: isEditMode ? "auto" : "none",
							}}
						>
							{title}
						</Typography>
					)}
				</Box>
			)}
		</Box>
	);
}

export default Cover;
