import React, { useRef, useState } from "react";
import "./ElementConfiguration.css";
import { ELEMENT_CONFIGURATIONS } from "./configElements";
import ApplyConfigButton from "./ApplyConfigButton/ApplyConfigButton"; // Import centralized config

function ElementConfiguration({ element, onConfigurationComplete }) {
	// get corresponding config for element
	const elementConfig = ELEMENT_CONFIGURATIONS[element];
	const ConfigComponent = elementConfig.configComponent;
	const [subPage, setSubPage] = useState(null);

	const applyConfigRef = useRef(null);
	const registerApplyConfig = (applyConfig) => {
		applyConfigRef.current = applyConfig;
	};

	const handleApplyConfigClick = () => {
		if (applyConfigRef.current) {
			const config = applyConfigRef.current();
			if (config) {
				onConfigurationComplete(config);
			}
		} else {
			console.error("applyConfigFunction is not available.");
		}
	};

	return (
		<div
			className="ElementConfiguration"
			style={{ width: elementConfig.width, height: elementConfig.height }}
		>
			{subPage && (
				<div className="ElementConfiguration-subPage" style={{}}>
					{subPage}
				</div>
			)}
			<div style={{ flexGrow: 1, overflow: "auto" }}>
				<ConfigComponent
					onConfigurationComplete={onConfigurationComplete}
					registerApplyConfig={registerApplyConfig}
					setSubPage={setSubPage}
				/>
			</div>

			{!elementConfig.noConfigButton && (
				<>
					<div style={{ height: "20px" }} />
					<ApplyConfigButton handleApply={handleApplyConfigClick} />
				</>
			)}
		</div>
	);
}

export default ElementConfiguration;
