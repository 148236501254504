import "./ShopItem.css";
import recordIcon from "../../../../../resources/record.png";
import React from "react";

function ShopItem(props) {
	const fileServerUrl = "http://localhost:8080";
	const imageUrl = `${fileServerUrl}/abilities/${props.identifier}.png`;

	return (
		<button className="ShopItem" onClick={() => props.onClick()}>
			<img src={imageUrl} className="ShopItem-coverImage" alt="" />
		</button>
	);
}

export default ShopItem;
