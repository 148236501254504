import "../DataTableConfig.css";
import React, { useState } from "react";
import {
	Box,
	Checkbox,
	IconButton,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutline";

export default function NewTableComponent({
	columns,
	newTable,
	onChange,
	onAddColumn,
	onDeleteColumn,
	errorFields,
	clearErrorFields,
}) {
	const [hoveredRow, setHoveredRow] = useState(null);

	const mainColumns = columns.filter((col) => !col.isSubRow);
	const subColumns = columns.filter((col) => col.isSubRow);

	// Helper function to check if a specific field has an error
	const hasError = (id, column) =>
		errorFields?.some(
			(error) => error.id === id && error.column === column,
		);

	return (
		<Table size="small" sx={{ tableLayout: "fixed" }}>
			<TableHead>
				<TableRow>
					{mainColumns.map((column) => (
						<TableCell
							key={column.columnName}
							sx={{ width: column.width }}
						>
							{column.label}
						</TableCell>
					))}
				</TableRow>
			</TableHead>
			<TableBody>
				<TableRow>
					{mainColumns.map((column) => (
						<TableCell key={column.columnName}>
							<TextField
								value={newTable[column.columnName]}
								onChange={(e) =>
									onChange(
										e.target.value,
										column.columnName,
										null,
									)
								}
								variant="standard"
								fullWidth
								sx={{
									margin: 0,
									padding: 0,
									fontSize: "inherit",
									animation: hasError(null, column.columnName)
										? "fadeInOutError 3s ease"
										: "none",
								}}
								onAnimationEnd={clearErrorFields}
							/>
						</TableCell>
					))}
				</TableRow>
				<TableRow>
					<TableCell colSpan={mainColumns.length}>
						<Box
							display="flex"
							alignItems="center"
							justifyContent="space-between"
						>
							<Typography
								variant="subtitle1"
								gutterBottom
								component="div"
							>
								Columns
							</Typography>
							<IconButton size="small" onClick={onAddColumn}>
								<AddIcon />
							</IconButton>
						</Box>
						<Table size="small">
							<TableHead>
								<TableRow>
									{subColumns.map((column) => (
										<TableCell
											key={column.columnName}
											sx={{ width: column.width }}
										>
											{column.label}
										</TableCell>
									))}
									<TableCell sx={{ width: "10%" }} />
								</TableRow>
							</TableHead>
							<TableBody>
								{newTable.columns.map((columnRow) => (
									<TableRow
										key={columnRow.id}
										hover
										onMouseEnter={() =>
											setHoveredRow(columnRow.id)
										}
										onMouseLeave={() => setHoveredRow(null)}
									>
										{subColumns.map((column) => (
											<TableCell key={column.columnName}>
												{column.type === "boolean" ? (
													<Checkbox
														checked={
															columnRow[
																column
																	.columnName
															]
														}
														onChange={(e) =>
															onChange(
																e.target
																	.checked,
																column.columnName,
																columnRow.id,
															)
														}
														sx={{ padding: 0 }}
													/>
												) : column.type === "select" &&
												  column.options.length > 0 ? (
													<Select
														value={
															columnRow[
																column
																	.columnName
															]
														}
														onChange={(e) =>
															onChange(
																e.target.value,
																column.columnName,
																columnRow.id,
															)
														}
														variant="standard"
														fullWidth
														sx={{
															margin: 0,
															padding: 0,
															fontSize: "inherit",
														}}
													>
														{column.options.map(
															(option) => (
																<MenuItem
																	key={
																		option.value
																	}
																	value={
																		option.value
																	}
																>
																	{
																		option.label
																	}
																</MenuItem>
															),
														)}
													</Select>
												) : (
													<TextField
														value={
															columnRow[
																column
																	.columnName
															]
														}
														onChange={(e) =>
															onChange(
																e.target.value,
																column.columnName,
																columnRow.id,
															)
														}
														variant="standard"
														fullWidth
														sx={{
															margin: 0,
															padding: 0,
															fontSize: "inherit",
															animation: hasError(
																columnRow.id,
																column.columnName,
															)
																? "fadeInOutError 3s ease"
																: "none",
														}}
														onAnimationEnd={
															clearErrorFields
														}
													/>
												)}
											</TableCell>
										))}
										<TableCell>
											<IconButton
												size="small"
												onClick={() =>
													onDeleteColumn(columnRow.id)
												}
												sx={{
													fontSize: "18px",
													visibility:
														hoveredRow ===
														columnRow.id
															? "visible"
															: "hidden",
												}}
											>
												<DeleteIcon />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	);
}
