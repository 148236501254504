import React from "react";
import "./DialogContent.css";

function DialogContent({ children }) {
	return (
		<div className="DialogContent" style={{ color: "white" }}>
			{children}
		</div>
	);
}

export default DialogContent;
