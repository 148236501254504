import React, { useEffect, useState } from "react";
import "./TextInput.css";
import TextField from "@mui/material/TextField";
import { usePalaceConfigContext } from "../../../../../services/PalaceConfigProvider";

function TextInput({ width, height }) {
	const { gridFieldSize } = usePalaceConfigContext();
	const [text, setText] = useState("");

	const factor = 2; // gridFieldSize has to be divisible by this factor

	return (
		<div className="TextInput">
			<TextField
				id="outlined-basic"
				label="Enter your text"
				variant="outlined"
				value={text}
				onChange={(e) => setText(e.target.value)}
				multiline // Makes it a textarea
				minRows={height * factor - 1}
				sx={{
					// Make the text field fill its parent container
					width: "100%",
					height: "100%",
					margin: 0,
					padding: 0,
					boxSizing: "border-box", // Ensures padding is accounted for within the width/height

					"& label": {
						color: "white", // Label text color
						//fontSize: height !== 1 ? "20px" : "15px", // Adjust label font size
						transform: text
							? "translate(16px, -8px) scale(0.75)"
							: "translate(14px, 8px) scale(1)", // Only transform when there's content or height condition
					},
					"& label.Mui-focused": {
						color: "white", // Label color when focused
						//fontSize: "16px", // Font size when label is focused
						//lineHeight: "1.6", // Adjust label height when focused
						transform: "translate(16px, -8px) scale(0.75)", // Label moves up and scales down when focused
					},
					"& .MuiOutlinedInput-root": {
						backgroundColor: "#092635",
						padding: `${(gridFieldSize / factor) * 0.55}px ${(gridFieldSize / factor) * 0.5}px ${(gridFieldSize / factor) * 0.45}px ${(gridFieldSize / factor) * 0.5}px`,
						"& fieldset": {
							borderColor: "white", // Outline color
						},
						"&:hover fieldset": {
							borderColor: "white", // Outline color on hover
						},
						"&.Mui-focused fieldset": {
							borderColor: "orange", // Outline color when focused
						},
					},
					"& .MuiInputBase-inputMultiline": {
						color: "white", // DataTable color for multiline textarea
						fontSize:
							height !== 1
								? `${gridFieldSize / factor / 1.2}px`
								: `${gridFieldSize / factor / 1.2}px`,
						lineHeight:
							height !== 1
								? `${gridFieldSize / factor}px`
								: `${gridFieldSize / factor}px`,
					},
				}}
			/>
		</div>
	);
}

export default TextInput;
