import React, { useEffect, useRef } from "react";
import "./Text.css";

function Text({ content = "testi" }) {
	const textRef = useRef(null);

	useEffect(() => {
		const resizeObserver = new ResizeObserver((entries) => {
			for (let entry of entries) {
				const { width, height } = entry.contentRect;
				const newFontSize = Math.min(width, height);
				if (textRef.current)
					textRef.current.style.fontSize = `${newFontSize}px`;
			}
		});

		if (textRef.current) {
			resizeObserver.observe(textRef.current);
		}

		return () => {
			if (textRef.current) {
				resizeObserver.unobserve(textRef.current);
			}
		};
	}, []);

	return (
		<div className="Text" ref={textRef}>
			{content}
		</div>
	);
}

export default Text;
