import "./StartPage.css";
import LoginButton from "./LoginButton/LoginButton";
import SignupButton from "./SignupButton/SignupButton";
import { useEffect } from "react";

function StartPage({}) {
	return (
		<div className="StartPage">
			<LoginButton />
			<SignupButton />
		</div>
	);
}

export default StartPage;
