import React, { createContext, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { jwtDecode } from "jwt-decode";

const AccessTokenContext = createContext({
	accessToken: null,
	isLoading: true,
	error: null,
});

// Static variable to store the access token for direct access
let staticAccessToken = null;

// Helper function to check token validity
const isTokenValid = (token) => {
	try {
		const decoded = jwtDecode(token);
		// Check if token has expired
		return decoded.exp * 1000 > Date.now();
	} catch (e) {
		return false;
	}
};

export const AccessTokenProvider = ({ children }) => {
	const [accessToken, setAccessToken] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const { isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup } =
		useAuth0();

	useEffect(() => {
		const fetchAccessToken = async () => {
			setIsLoading(true);
			setError(null);

			// Current solution for ios app - pass the access_token via localStorage - delete after logout
			let storedToken = localStorage.getItem("access_token");
			if (storedToken && isTokenValid(storedToken)) {
				setAccessToken(storedToken);
				staticAccessToken = storedToken;
				setIsLoading(false);
				return;
			}

			try {
				if (isAuthenticated) {
					let token = await getAccessTokenSilently({
						authorizationParams: {
							audience: `https://thought-palace`,
							scope: "openid offline_access read:current_user",
						},
					});

					// Set token if successful
					setAccessToken(token);
					staticAccessToken = token;
				}
			} catch (error) {
				console.error("Error fetching access token:", error);
				setError(error); // Set error state
			} finally {
				setIsLoading(false);
			}
		};

		fetchAccessToken();
	}, [isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup]);

	// Logout handler to clear the token
	const clearAccessToken = () => {
		setAccessToken(null);
		staticAccessToken = null;
		localStorage.removeItem("access_token");
	};

	return (
		<AccessTokenContext.Provider
			value={{ accessToken, isLoading, error, clearAccessToken }}
		>
			{children}
		</AccessTokenContext.Provider>
	);
};

export const useAccessToken = () => React.useContext(AccessTokenContext);

// Static method to get the access token directly
export const AccessTokenStaticProvider = {
	getAccessToken: () => staticAccessToken,
};
