import React from "react";
import "./AI.css";
import Room3d from "../../views/Palace/Room/Room3d/Room3d";

function AI({}) {
	return (
		<div className="AI">
			<div style={{ height: "38px", aspectRatio: "1/1" }}>
				<Room3d
					width={1}
					height={1}
					gridFieldSize={38}
					animation={"weird"}
					type={"skeleton"}
				/>
			</div>
		</div>
	);
}

export default AI;
