import React from "react";
import "./ActionButton.css";
import bgImage from "../../../../../resources/colour_gradient_blurred.png";
import Button from "@mui/material/Button";

function ActionButton({}) {
	return (
		<div className="ActionButton">
			<Button
				variant="contained"
				style={{
					textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
					color: "#fff",
					width: "100%",
					height: "100%",
				}}
				onClick={() => console.log("hello")}
			>
				Get started
			</Button>
		</div>
	);
}

export default ActionButton;
