import React from "react";
import IconButton from "@mui/material/IconButton";
import "./IconPicker.css";

function IconPicker({ onIconSelect, icons }) {
	return (
		<div className="IconPicker">
			<div className="IconPicker-grid">
				{icons.map((iconObj, index) => (
					<IconButton
						key={index}
						onClick={() => onIconSelect(iconObj)}
					>
						<iconObj.component />
					</IconButton>
				))}
			</div>
		</div>
	);
}

export default IconPicker;
