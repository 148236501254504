import React, { createContext, useState, useEffect } from "react";
import { useAccessToken } from "./AccessTokenProvider";

const UserDataContext = createContext(null);

export const UserDataProvider = ({ children }) => {
	const [userData, setUserData] = useState(undefined);
	const { accessToken } = useAccessToken();

	const fetchUserData = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_BASE_URL}/users/get-user-data`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${accessToken}`,
					},
				},
			);

			const data = await response.json();
			setUserData(data);
		} catch (error) {
			console.error("Error fetching user data:", error);
		}
	};

	const reloadUserData = () => {
		fetchUserData();
	};

	useEffect(() => {
		if (!accessToken) return; // Don't proceed if accessToken is null

		fetchUserData();
	}, [accessToken]);

	return (
		<UserDataContext.Provider value={{ userData, reloadUserData }}>
			{children}
		</UserDataContext.Provider>
	);
};

export const useUserData = () => React.useContext(UserDataContext);
