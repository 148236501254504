import React, { createContext, useContext } from "react";

const PalaceConfigContext = createContext();

export const PalaceDataProvider = ({
	children,
	gridFieldSize,
	palaceWidth,
	palaceHeight,
	pushDialog,
	popDialog,
	updatePalaceData,
	updatePalaceDataWithSave,
	findElementById,
}) => {
	const isMultiSelectModifierPressed = (event) => {
		return event.ctrlKey || event.metaKey; // Handle both Windows (Ctrl) and Mac (Cmd)
	};
	return (
		<PalaceConfigContext.Provider
			value={{
				gridFieldSize,
				palaceWidth,
				palaceHeight,
				pushDialog,
				popDialog,
				updatePalaceData,
				updatePalaceDataWithSave,
				findElementById,
				isMultiSelectModifierPressed,
			}}
		>
			{children}
		</PalaceConfigContext.Provider>
	);
};

export const usePalaceConfigContext = () => useContext(PalaceConfigContext);

export default PalaceConfigContext;
