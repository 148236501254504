// ThemeProvider.js
import React, { createContext, useContext, useState } from "react";
import themeConfig from "./themeConfig";

const ThemeProvider = createContext();

export const useThemeContext = () => useContext(ThemeProvider);

export const ThemeContextProvider = ({ children }) => {
	const [themeMode, setThemeMode] = useState("default"); // Use "default" as the initial mode

	const toggleThemeMode = () => {
		setThemeMode((prevMode) => {
			switch (prevMode) {
				case "default":
					return "dark";
				case "dark":
					return "transparent";
				case "transparent":
					return "default";
				default:
					return "default";
			}
		});
	};

	// A function to get the theme based on component type and dark mode setting
	const getThemeForComponent = (componentType) => {
		return themeConfig[componentType]?.[themeMode] || {};
	};

	return (
		<ThemeProvider.Provider
			value={{ themeMode, toggleThemeMode, getThemeForComponent }}
		>
			{children}
		</ThemeProvider.Provider>
	);
};
