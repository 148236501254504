// DataTableConfig.js
import React, { useEffect, useState } from "react";
import {
	Button,
	Paper,
	TableContainer,
	ToggleButton,
	ToggleButtonGroup,
} from "@mui/material";
import ExistingTablesComponent from "./ExistingTablesComponent/ExistingTablesComponent";
import NewTableComponent from "./NewTableComponent/NewTableComponent";
import TextField from "@mui/material/TextField";
import { useAccessToken } from "../../../../../../services/AccessTokenProvider";

export default function DataTableConfig({ registerApplyConfig }) {
	// Define column configurations for both main and inner tables
	const existingTableColumns = [
		{ label: "Table Name", columnName: "display_name", width: "30%" },
		{ label: "Description", columnName: "table_description", width: "70%" },
	];

	const newTableColumns = [
		{
			label: "Table Name",
			columnName: "table_name",
			width: "25%",
			isSubRow: false,
		},
		{
			label: "Description",
			columnName: "table_description",
			width: "75%",
			isSubRow: false,
		},
		{
			label: "Name",
			columnName: "column_name",
			width: "20%",
			isSubRow: true,
		},
		{
			label: "Description",
			columnName: "column_description",
			width: "60%",
			isSubRow: true,
		},
		{
			label: "Type",
			columnName: "column_type",
			type: "select",
			options: [
				{ label: "Text", value: "string" },
				{ label: "Number", value: "number" },
				{ label: "Date", value: "date" },
			],
			width: "20%",
			isSubRow: true,
		},
	];

	const { accessToken } = useAccessToken();
	const [chooseTableOptions, setChooseTableOptions] = useState("create_new");
	const [selectedRow, setSelectedRow] = useState(null);
	const [errorFields, setErrorFields] = useState(null);
	const [existingTables, setExistingTables] = useState([]);
	const [newTable, setNewTable] = useState({
		table_name: "",
		table_description: "",
		columns: [
			{
				id: 1,
				column_name: "",
				column_description: "",
				column_type: "string",
			},
		],
	});

	useEffect(() => {
		// Fetch the list of tables when the component mounts
		const fetchTables = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_BASE_URL}/thought-palace/get-list-of-tables`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${accessToken}`,
						},
					},
				);
				if (response.ok) {
					const data = await response.json();
					setExistingTables(data.response);
				}
			} catch (error) {
				console.error("Failed to fetch tables:", error);
			}
		};

		fetchTables();
	}, []);

	// register the apply config function in parent so that the apply button can execute this function
	registerApplyConfig(() => {
		if (validateFields()) {
			let config;
			if (chooseTableOptions === "create_new") {
				const columnsWithoutIds = newTable.columns.map(
					({ id, ...rest }) => rest,
				);
				config = {
					assignTableType: chooseTableOptions,
					tableInfo: { ...newTable, columns: columnsWithoutIds },
				};
			} else if (chooseTableOptions === "existing") {
				config = {
					assignTableType: chooseTableOptions,
					tableName: selectedRow,
				};
			}

			return config;
		}
	});

	const handleSelectRow = (rowName) => {
		setSelectedRow((prevSelectedRow) =>
			prevSelectedRow === rowName ? null : rowName,
		);
	};

	const handleChangeTableOption = (event, newValue) => {
		if (newValue !== null) {
			setErrorFields(null);
			setChooseTableOptions(newValue);
		}
	};

	const handleNewTableChange = (newValue, field, columnId) => {
		setNewTable((prev) => {
			if (columnId === null) {
				// Update the main row fields
				return { ...prev, [field]: newValue };
			} else {
				// Update the columns (subrows)
				const updatedColumns = prev.columns.map((column) =>
					column.id === columnId
						? { ...column, [field]: newValue }
						: column,
				);
				return { ...prev, columns: updatedColumns };
			}
		});
	};

	const handleAddColumn = () => {
		setNewTable((prev) => {
			const newColumn = {
				id: Date.now(),
				column_name: "",
				column_description: "",
				column_type: "string",
			};
			return { ...prev, columns: [...prev.columns, newColumn] };
		});
	};

	const handleDeleteColumn = (columnId) => {
		if (newTable.columns.length === 1) {
			return;
		}
		setNewTable((prev) => {
			const updatedColumns = prev.columns.filter(
				(column) => column.id !== columnId,
			);
			return { ...prev, columns: updatedColumns };
		});
	};

	const validateFields = () => {
		const errors = [];

		if (chooseTableOptions === "create_new") {
			let requiredColumn = "table_name";
			if (!newTable[requiredColumn].trim()) {
				errors.push({ id: null, column: requiredColumn });
			}

			requiredColumn = "column_name";
			newTable.columns.forEach((col) => {
				if (!col[requiredColumn].trim()) {
					errors.push({ id: col.id, column: requiredColumn }); //
				}
			});
		} else if (chooseTableOptions === "existing") {
			if (selectedRow === null) {
				errors.push("nothing selected");
			}
		}

		setErrorFields(errors);

		// Return true if there are no errors
		return errors.length === 0;
	};

	const handleAnimationEnd = (event) => {
		// Check if the animation that ended is 'fadeInOutError'
		if (event.animationName === "fadeInOutError") {
			setErrorFields(null); // Clear the errors after the animation ends
		}
	};

	return (
		<div
			className="DataTableConfig"
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				height: "100%",
			}}
		>
			<ToggleButtonGroup
				value={chooseTableOptions}
				exclusive
				onChange={handleChangeTableOption}
			>
				<ToggleButton value="create_new">Create new table</ToggleButton>
				{existingTables.length > 0 && (
					<ToggleButton value="existing">
						Use existing table
					</ToggleButton>
				)}
				<ToggleButton value="description">
					Describe your table
				</ToggleButton>
			</ToggleButtonGroup>
			<div
				style={{
					flexGrow: 1,
					padding: "20px 0 0 0",
					overflow: "hidden",
				}}
			>
				{chooseTableOptions === "description" ? (
					<TextField
						label="Description"
						multiline
						value={""}
						sx={{ width: "100%", margin: 0 }}
						minRows={10}
						maxRows={10}
					/>
				) : (
					<TableContainer
						sx={{
							height: "100%", // Make it take the full available height
							overflow: "auto", // Enable scrolling if content overflows
							boxSizing: "border-box",
							padding: "0 1px 0 0 ",
							maxHeight: "100%",
							maxWidth: "100%",
							"&::-webkit-scrollbar": {
								width: 8,
								height: 8,
							},
							"&::-webkit-scrollbar-track": {
								backgroundColor: "white",
							},
							"&::-webkit-scrollbar-thumb": {
								backgroundColor: "grey",
								borderRadius: 3,
							},
							"&::-webkit-scrollbar-thumb:hover": {
								backgroundColor: "dark-grey",
							},
						}}
					>
						{chooseTableOptions === "existing" ? (
							<ExistingTablesComponent
								columns={existingTableColumns}
								rows={existingTables}
								selectedRow={selectedRow}
								onSelect={handleSelectRow}
								errorFields={errorFields}
								clearErrorFields={handleAnimationEnd}
							/>
						) : (
							<NewTableComponent
								columns={newTableColumns}
								newTable={newTable}
								onChange={handleNewTableChange}
								onAddColumn={handleAddColumn}
								onDeleteColumn={handleDeleteColumn}
								errorFields={errorFields}
								clearErrorFields={handleAnimationEnd}
							/>
						)}
					</TableContainer>
				)}
			</div>
		</div>
	);
}
