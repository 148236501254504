import React, { useState } from "react";
import "./Dialog.css";

function Dialog({ children, closable = false, onClose, width, style }) {
	const handleOutsideClick = (e) => {
		e.stopPropagation();

		if (closable && e.target.classList.contains("Dialog")) {
			onClose();
		}
	};

	// Determine dialog size class based on width prop
	const sizeClass = width ? `Dialog--${width}` : "";

	return (
		<div className="Dialog" onMouseDown={handleOutsideClick}>
			<div className={`Dialog-box ${sizeClass}`} style={style}>
				{children}
			</div>
		</div>
	);
}

export default Dialog;
