import "../DataTableConfig.css";
import React, { useState } from "react";
import {
	Box,
	Checkbox,
	keyframes,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material";

export default function ExistingTablesComponent({
	columns,
	rows,
	selectedRow,
	onSelect,
	errorFields,
	clearErrorFields,
}) {
	function Row({ row, selectedRow, onSelect }) {
		const isSelected = selectedRow === row.table_name;

		return (
			<TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
				<TableCell padding="checkbox">
					<Checkbox
						checked={isSelected}
						onChange={() => onSelect(row.table_name)}
						sx={{
							animation:
								errorFields?.length > 0
									? "fadeInOutError 3s ease"
									: "none",
						}}
						onAnimationEnd={clearErrorFields}
					/>
				</TableCell>
				{columns.map((column) => (
					<TableCell key={column.columnName}>
						{row[column.columnName]}
					</TableCell>
				))}
			</TableRow>
		);
	}

	return (
		<Table stickyHeader sx={{ tableLayout: "fixed" }}>
			<TableHead>
				<TableRow>
					<TableCell sx={{ width: "3%" }}></TableCell>
					{columns.map((column) => (
						<TableCell
							key={column.columnName}
							sx={{ width: column.width }}
						>
							{column.label}
						</TableCell>
					))}
				</TableRow>
			</TableHead>
			<TableBody>
				{rows.map((row) => (
					<Row
						key={row.table_name}
						row={row}
						selectedRow={selectedRow}
						onSelect={onSelect}
					/>
				))}
			</TableBody>
		</Table>
	);
}
