import React, { useState, useEffect } from "react";
import Dialog from "../../../Dialog/Dialog";
import TextField from "@mui/material/TextField";
import DialogTitle from "../../../Dialog/DialogTitle/DialogTitle";
import DialogContent from "../../../Dialog/DialogContent/DialogContent";
import DialogSwitch from "../../../Dialog/DialogContent/DialogSwitch/DialogSwitch";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import DialogContentSection from "../../../Dialog/DialogContent/DialogContentSection/DialogContentSection";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import DialogPicker from "../../../Dialog/DialogPicker/DialogPicker";
import DialogPickerItem from "../../../Dialog/DialogPicker/DialogPickerItem/DialogPickerItem";
import { formatCalendarDate } from "../Calendar";

function CalendarEventDialog({ onClose, selectInfo, onSave, initialEvent }) {
	const [eventTitle, setEventTitle] = useState("");
	const [allDay, setAllDay] = useState(false);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [repeat, setRepeat] = useState("Never");
	const [repeatInDays, setRepeatInDays] = useState(1);
	const [endRepeat, setEndRepeat] = useState("Never");
	const [endRepeatDate, setEndRepeatDate] = useState(null);
	const [invalidFields, setInvalidFields] = useState([]);
	const [showSecondDialog, setShowSecondDialog] = useState(false);

	useEffect(() => {
		if (initialEvent) {
			// Existing event, populate fields
			setEventTitle(initialEvent.title);
			setAllDay(initialEvent.allDay);
			setStartDate(new Date(initialEvent.start));
			setEndDate(new Date(initialEvent.end));
			setRepeat(initialEvent.repeat);
			setRepeatInDays(initialEvent.repeatInDays || 1);
			setEndRepeat(initialEvent.endRepeat || "Never");
			setEndRepeatDate(
				initialEvent.endRepeatDate
					? new Date(initialEvent.endRepeatDate)
					: null,
			);
		} else {
			// New event, initialize start and end dates
			setAllDay(selectInfo.allDay || false);

			if (selectInfo.startStr) {
				setStartDate(new Date(selectInfo.startStr));
			}
			if (selectInfo.endStr) {
				const end = new Date(selectInfo.endStr);
				if (selectInfo.allDay) {
					end.setDate(end.getDate() - 1);
				}
				setEndDate(end);
			} else if (selectInfo.startStr) {
				// If end date is not provided, set it to one hour after start date
				const end = new Date(selectInfo.startStr);
				end.setHours(end.getHours() + 1);
				setEndDate(end);
			}

			setEndRepeat("Never");
			const weekFromNow = new Date();
			weekFromNow.setDate(weekFromNow.getDate() + 7);
			setEndRepeatDate(weekFromNow);
		}
	}, [initialEvent, selectInfo]);

	const handleAllDayChange = () => {
		const newAllDay = !allDay;
		setAllDay(newAllDay);

		if (newAllDay) {
			// All-day is set to true
			const start = new Date(startDate);
			const end = new Date(endDate);
			start.setHours(0, 0, 0, 0);
			end.setHours(0, 0, 0, 0);
			setStartDate(start);
			setEndDate(end);
		} else {
			// All-day is set to false
			const start = new Date(startDate);
			const end = new Date(startDate); // also startDate

			const currentTime = new Date();
			const currentHours = currentTime.getHours();
			const currentMinutes = currentTime.getMinutes();

			if (
				start.getFullYear() === end.getFullYear() &&
				start.getMonth() === end.getMonth() &&
				start.getDate() === end.getDate()
			) {
				// If the start and end date are the same, set start time to current time
				// and end time to current time + 1 hour
				start.setHours(currentHours, currentMinutes, 0, 0);
				end.setHours(currentHours + 1, currentMinutes, 0, 0);
			} else {
				// If the start and end date are different, set both to current time
				start.setHours(currentHours, currentMinutes, 0, 0);
				end.setHours(currentHours, currentMinutes, 0, 0);
			}

			setStartDate(start);
			setEndDate(end);
		}

		// Remove any invalid field errors related to dates
		removeInvalidField("start");
		removeInvalidField("end");
	};

	const validateFields = () => {
		const invalid = [];

		if (!eventTitle.trim()) {
			invalid.push("title");
		}

		if (!startDate) {
			invalid.push("start");
		}

		if (!endDate) {
			invalid.push("end");
		}

		const stripTime = (date) => {
			const d = new Date(date);
			d.setHours(0, 0, 0, 0);
			return d;
		};

		if (startDate && endDate) {
			if (allDay) {
				// For all-day events, compare only the date part
				const strippedStartDate = stripTime(startDate);
				const strippedEndDate = stripTime(endDate);

				if (strippedEndDate < strippedStartDate) {
					invalid.push("end");
				}
			} else {
				// For timed events, end date must be > start date
				if (endDate <= startDate) {
					invalid.push("end");
				}
			}
		}

		if (repeat === "Custom" && (!repeatInDays || repeatInDays < 1)) {
			invalid.push("repeatInDays");
		}

		if (repeat !== "Never" && endRepeat === "On Date") {
			if (!endRepeatDate) {
				invalid.push("endRepeatDate");
			} else {
				// Validate that endRepeatDate is after endDate
				const eventEnd = new Date(endDate);
				const repeatEnd = new Date(endRepeatDate);

				// Adjust eventEnd if all-day to include the entire day
				if (allDay) {
					eventEnd.setDate(eventEnd.getDate() + 1);
				}

				if (repeatEnd <= eventEnd) {
					invalid.push("endRepeatDate");
				}
			}
		}

		if (invalid.length > 0) {
			setInvalidFields(invalid);
			return false;
		}

		return true;
	};

	const removeInvalidField = (field) => {
		setInvalidFields((prev) => prev.filter((item) => item !== field));
	};

	const handleSave = () => {
		if (validateFields()) {
			if (
				initialEvent &&
				initialEvent.repeat &&
				initialEvent.repeat !== "Never"
			) {
				setShowSecondDialog(true);
			} else {
				saveEvent(null);
			}
		}
	};

	const saveEvent = (type) => {
		// Always add one day to the end date for all-day events
		let adjustedEndDate = endDate;
		if (allDay) {
			const end = new Date(endDate);
			end.setDate(end.getDate() + 1);
			adjustedEndDate = end;
		}

		const eventData = {
			id: initialEvent?.id || String(new Date().getTime()),
			title: eventTitle,
			allDay,
			start: formatCalendarDate(startDate, allDay), // Use formatted date
			end: formatCalendarDate(adjustedEndDate, allDay), // Use formatted date
			repeat,
			repeatInDays: repeat === "Custom" ? repeatInDays : null,
			endRepeat,
			endRepeatDate:
				endRepeat === "On Date" && endRepeatDate
					? formatCalendarDate(endRepeatDate, true) // Format as YYYY-MM-DD
					: null,
			type,
		};

		onSave(eventData);
		onClose();
	};

	const commonTextFieldStyles = {
		input: {
			color: "#9e9e9e", // Input text color
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "#9e9e9e", // Non-focused border color
			},
			"&:hover fieldset": {
				borderColor: "rgb(233,233,233)", // Hover border color
			},
		},
		"& .MuiInputLabel-root": {
			color: "#9e9e9e", // Default label color
		},
	};

	const getCommonSlotProps = (field) => ({
		textField: {
			fullWidth: true,
			error: invalidFields.includes(field), // Dynamically set error
			margin: "dense",
			size: "small",
			sx: {
				"& .MuiInputBase-input": {
					color: "#9e9e9e", // Input text color
				},
				"& .MuiInputLabel-root": {
					color: "#9e9e9e", // Label color
				},
				"& .MuiSvgIcon-root": {
					color: "#9e9e9e", // Icon color
				},
				"& .MuiOutlinedInput-root": {
					"& fieldset": {
						borderColor: "#9e9e9e", // Non-focused border color
					},
					"&:hover fieldset": {
						borderColor: "rgb(233,233,233)", // Hover border color
					},
				},
			},
		},
		popper: {
			sx: {
				"& .MuiMultiSectionDigitalClock-root": {
					"& .MuiMultiSectionDigitalClockSection-root": {
						"&::-webkit-scrollbar": {
							width: "8px",
						},
						"&::-webkit-scrollbar-thumb": {
							backgroundColor: "#9e9e9e",
						},
						"&::-webkit-scrollbar-track": {
							backgroundColor: "transparent",
						},
					},
				},
			},
		},
	});

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<Dialog
				closable={true}
				onClose={onClose}
				width="small"
				style={{
					backgroundColor: "#333333",
					padding: showSecondDialog ? "0" : "20px",
				}}
			>
				{!showSecondDialog ? (
					<>
						<DialogTitle>
							{initialEvent ? "Edit Event" : "Add Event"}
						</DialogTitle>
						<DialogContent>
							<TextField
								id="outlined-basic"
								label="Title"
								variant="outlined"
								value={eventTitle}
								onChange={(e) => {
									setEventTitle(e.target.value);
									if (e.target.value)
										removeInvalidField("title");
								}}
								fullWidth
								margin="dense"
								error={invalidFields.includes("title")}
								size="small"
								color="primary"
								sx={commonTextFieldStyles}
							/>
							<DialogSwitch
								label="All-day"
								checked={allDay}
								onChange={handleAllDayChange}
								margin="dense"
							/>

							<DialogContentSection>
								{allDay ? (
									<DatePicker
										label="Start"
										value={startDate}
										onChange={(newValue) => {
											setStartDate(newValue);
											if (newValue)
												removeInvalidField("start");
										}}
										slotProps={getCommonSlotProps("start")}
									/>
								) : (
									<DateTimePicker
										label="Start"
										value={startDate}
										onChange={(newValue) => {
											setStartDate(newValue);
											if (newValue)
												removeInvalidField("start");
										}}
										slotProps={getCommonSlotProps("start")}
									/>
								)}

								{allDay ? (
									<DatePicker
										label="End"
										value={endDate}
										onChange={(newValue) => {
											setEndDate(newValue);
											if (newValue)
												removeInvalidField("end");
										}}
										slotProps={getCommonSlotProps("end")}
									/>
								) : (
									<DateTimePicker
										label="End"
										value={endDate}
										onChange={(newValue) => {
											setEndDate(newValue);
											if (newValue)
												removeInvalidField("end");
										}}
										slotProps={getCommonSlotProps("end")}
									/>
								)}
							</DialogContentSection>

							<DialogContentSection>
								<FormControl
									fullWidth
									margin="dense"
									size="small"
									sx={{
										"& .MuiInputLabel-root": {
											color: "#9e9e9e", // Default label color
										},
										"& .MuiOutlinedInput-root": {
											"& fieldset": {
												borderColor: "#9e9e9e", // Non-focused border color
											},
											"&:hover fieldset": {
												borderColor: "rgb(233,233,233)", // Hover border color
											},
											"& .MuiSelect-select": {
												color: "#9e9e9e", // Dropdown text color
											},
										},
										"& .MuiSvgIcon-root": {
											color: "#9e9e9e", // Dropdown arrow color
										},
									}}
								>
									<InputLabel id="repeat-label">
										Repeat
									</InputLabel>
									<Select
										labelId="repeat-label"
										value={repeat}
										onChange={(e) =>
											setRepeat(e.target.value)
										}
										label="Repeat"
									>
										<MenuItem value="Never">Never</MenuItem>
										<MenuItem value="Every Day">
											Every Day
										</MenuItem>
										<MenuItem value="Every Week">
											Every Week
										</MenuItem>
										<MenuItem value="Every Month">
											Every Month
										</MenuItem>
										<MenuItem value="Every Year">
											Every Year
										</MenuItem>
										<MenuItem value="Custom">
											Custom
										</MenuItem>
									</Select>
								</FormControl>

								{repeat === "Custom" && (
									<TextField
										label="Repeat in days"
										type="number"
										value={repeatInDays}
										onChange={(e) => {
											setRepeatInDays(e.target.value);
											if (e.target.value)
												removeInvalidField(
													"repeatInDays",
												);
										}}
										InputLabelProps={{ shrink: true }}
										inputProps={{ min: 1, max: 365 }}
										fullWidth
										margin="dense"
										error={invalidFields.includes(
											"repeatInDays",
										)}
										size="small"
										sx={commonTextFieldStyles}
									/>
								)}

								{repeat !== "Never" && (
									<FormControl
										fullWidth
										margin="dense"
										size="small"
										sx={{
											"& .MuiInputLabel-root": {
												color: "#9e9e9e", // Default label color
											},
											"& .MuiOutlinedInput-root": {
												"& fieldset": {
													borderColor: "#9e9e9e", // Non-focused border color
												},
												"&:hover fieldset": {
													borderColor:
														"rgb(233,233,233)", // Hover border color
												},
												"& .MuiSelect-select": {
													color: "#9e9e9e", // Dropdown text color
												},
											},
											"& .MuiSvgIcon-root": {
												color: "#9e9e9e", // Dropdown arrow color
											},
										}}
									>
										<InputLabel id="end-repeat-label">
											End Repeat
										</InputLabel>
										<Select
											labelId="end-repeat-label"
											value={endRepeat}
											onChange={(e) => {
												setEndRepeat(e.target.value);
												if (
													e.target.value === "Never"
												) {
													setEndRepeatDate(null);
												} else if (!endRepeatDate) {
													const weekFromNow =
														new Date();
													weekFromNow.setDate(
														weekFromNow.getDate() +
															7,
													);
													setEndRepeatDate(
														weekFromNow,
													);
												}
											}}
											label="End Repeat"
										>
											<MenuItem value="Never">
												Never
											</MenuItem>
											<MenuItem value="On Date">
												On Date
											</MenuItem>
										</Select>
									</FormControl>
								)}

								{repeat !== "Never" &&
									endRepeat === "On Date" && (
										<DatePicker
											label="End Date"
											value={endRepeatDate}
											onChange={(newValue) => {
												setEndRepeatDate(newValue);
												if (newValue)
													removeInvalidField(
														"endRepeatDate",
													);
											}}
											slotProps={getCommonSlotProps(
												"endRepeatDate",
											)}
										/>
									)}
							</DialogContentSection>

							<Button
								variant="contained"
								color="primary"
								onClick={handleSave}
								fullWidth
								sx={{ marginTop: "20px" }}
								size="large"
							>
								{initialEvent ? "Save Changes" : "Save Event"}
							</Button>
						</DialogContent>
					</>
				) : (
					<DialogPicker>
						<DialogPickerItem id="present" clickEvent={saveEvent}>
							Change this event
						</DialogPickerItem>
						<DialogPickerItem id="future" clickEvent={saveEvent}>
							Change all future events
						</DialogPickerItem>
					</DialogPicker>
				)}
			</Dialog>
		</LocalizationProvider>
	);
}

export default CalendarEventDialog;
