import "./Page.css";
import Sidebar from "./Sidebar/Sidebar";
import { useUserData } from "../../services/UserDataProvider";
import Welcome from "./Welcome/Welcome";

function Page(props) {
	const { userData } = useUserData();

	return (
		<div className="Page">
			<Sidebar />
			<div className="Page-content">{props.children}</div>
			{userData === null && <Welcome />}
		</div>
	);
}

export default Page;
