// TextEditor.js
import React, { useState } from "react";
import "./TextEditor.css";

function TextEditor({ fileContent, onSave, onClose }) {
	const [content, setContent] = useState(fileContent);

	const handleSave = () => {
		onSave(content);
		onClose();
	};

	return (
		<div className="TextEditorOverlay">
			<div className="TextEditor">
				<textarea
					value={content}
					onChange={(e) => setContent(e.target.value)}
					className="TextEditorArea"
				/>
				<div className="TextEditorActions">
					<button onClick={handleSave}>Save</button>
					<button onClick={onClose}>Close</button>
				</div>
			</div>
		</div>
	);
}

export default TextEditor;
